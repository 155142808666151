@mixin background($url) {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url($url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@mixin maxLine($line) {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  white-space: pre-line;
  -webkit-line-clamp: $line;
}
