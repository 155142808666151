.MuiButton-root.Button {
  padding: 10px 22px;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-transform: none;
  box-shadow: none;
  height: 40px;
  transition: all 0.3s ease;
  color: white;
  border-radius: 4px;

  &:hover {
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  i {
    font-size: 16px;
    margin-left: 8px;

    &.Button-icon {
      font-size: 24px;
      margin-right: -4px;

      &.no-ml {
        margin-left: -4px;
      }
    }
  }

  .border {
    display: none;
    border-radius: inherit;
  }

  &-default {
    background: var(--cl-primary);
    color: white;
    padding: 14px 22px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: auto;
    border-radius: 2px;

    &.small {
      padding: 12px 16px;
      min-width: 0;
    }

    &.secondary {
      .border {
        display: block;
        border: 1px solid var(--cl-primary);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      color: var(--cl-secondary-1);
      background-color: white;

      &.big-radius {
        font-size: 18px;
        line-height: 24px;
        border-radius: 8px;
        padding: 14px 66px;
      }
    }

    &.semantic {
      padding: 12px 16px;
      background-color: var(--cl-semantic-1);
    }

    &:hover,
    &.semantic:hover {
      @media screen and (max-width: 860px) {
        background: var(--cl-primary);
        color: white;
      }
      background: var(--cl-accent-1);
      color: var(--cl-primary);
    }

    &:disabled {
      background: var(--cl-neutral-3);
      color: white;
      opacity: 1;
    }
  }

  &-top {
    background: var(--cl-semantic-1);

    &:hover {
      background: var(--cl-semantic-2);
    }
  }

  &-none {
    color: var(--cl-neutral-1);
    background-color: transparent;
    height: 40px;
    min-width: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      background-color: transparent;
    }
  }

  &-cta {
    height: 64px;
    background: var(--cl-semantic-1);
    border-radius: 2px;
    padding: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    justify-content: space-between;

    &.primary {
      color: white;
      background-color: var(--cl-primary);
    }

    &.white {
      color: var(--cl-primary);
      background-color: white;
    }

    i {
      font-size: 18px;
      margin-left: 16px;
    }

    &:hover {
      background: var(--cl-accent-1);
      color: var(--cl-primary);
    }
  }
}

@media screen and (max-width: 860px) {
  .MuiButton-root.Button {
    padding: 4px 12px;

    i {
      &.Button-icon {
        font-size: 20px;
      }
    }

    &-top {
      height: 28px;
    }

    &-cta {
      height: 44px;
      border-radius: 4px;
      padding: 12px 16px;
    }

    &-default {
      height: 40px;
      padding: 10px 16px;
      font-size: 14px;
      line-height: 20px;

      &.small {
        padding: 6px 10px;
        min-width: 0;
        height: auto;
        border-radius: 2px;
      }

      &.secondary {
        &.big-radius {
          font-size: 14px;
          line-height: 24px;
          border-radius: 4px;
          padding: 8px 24px;
        }
      }
    }
  }
}

.MuiButton-root.ButtonArrow {
  background-color: white;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--cl-primary);
  border-radius: 2px;
  color: var(--cl-primary);
  font-size: 40px;
  z-index: 2;
  position: absolute;
  top: 50%;
  transition: all 0.3s ease;

  &::before {
    display: none;
  }

  &.slick-disabled {
    display: none;

    &.disabled {
      display: block;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &.slick-prev {
    transform: rotate(180deg) translateY(-50%);
    transform-origin: top;
  }

  &.slick-next {
    transform: translateY(-50%);
  }

  &:focus {
    color: var(--cl-primary);
  }

  &:hover {
    color: white;
    border: 1px solid var(--cl-primary);
    background-color: var(--cl-primary);
  }

  &.big {
    width: 52px;
    height: 52px;
    font-size: 24px;
  }

  &.outline {
    border-color: white;
    background-color: transparent;
    color: white;

    &:focus {
      color: white;
    }

    &:hover {
      color: white;
      border: 1px solid var(--cl-primary);
      background-color: var(--cl-primary);

      &.hoverAccent {
        color: var(--cl-primary);
        border: 1px solid var(--cl-accent-1);
        background-color: var(--cl-accent-1);
      }
    }
  }

  @media not all and (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: white;
      border: 1px solid var(--cl-primary);
      color: var(--cl-primary);
    }

    &.outline {
      &:hover {
        border-color: white;
        background-color: transparent;
        color: white;

        &.hoverAccent {
          border-color: white;
          background-color: transparent;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .MuiButton-root.ButtonArrow {
    min-width: 40px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    padding: 0;

    &.big {
      width: 40px;
      height: 40px;
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 860px) {
  .MuiButton-root.ButtonArrow {
    min-width: 24px;
    width: 24px;
    height: 24px;
    font-size: 20px;
    padding: 0;

    &.big {
      width: 28px;
      height: 28px;
      font-size: 20px;
    }

    &.outline {
      display: none;
    }
  }
}
