.UserType {
  margin-top: 126px;
  background: #ffffff;
  border-radius: 4px;
  padding: 0px 40px 40px;
  transition: box-shadow 0.3s ease;

  .background-image {
    transform: translateY(-126px);
    margin-bottom: -126px;
    width: 320px;
    height: 320px;
    max-width: 100%;
    border-radius: 2px;
  }

  .body {
    margin-top: 24px;

    .rank {
      color: var(--cl-neutral-2);
      margin-bottom: 4px;
    }

    .fullName {
      color: var(--cl-primary);
      margin-bottom: 4px;
      transition: color 0.3s ease;
    }

    .regency {
      color: var(--cl-semantic-1);
      line-height: 24px;
    }

    .hr {
      border-bottom: 1px solid var(--cl-neutral-4);
      border-radius: 2px;
      margin: 16px 0;
      transition: border-color 0.3s ease;
    }

    .iconWrapper {
      color: var(--cl-neutral-2);
      text-overflow: ellipsis;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      i {
        font-size: 24px;
        color: var(--cl-primary);
        margin-right: 16px;
        transition: color 0.3s ease;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);

    .body {
      .fullName {
        color: var(--cl-neutral-1);
      }

      .hr {
        border-bottom: 1px solid var(--cl-neutral-2);
      }

      .iconWrapper {
        i {
          color: var(--cl-neutral-1);
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .UserType {
    padding: 0px 24px 24px;

    .background-image {
      width: 100%;
      height: 240px;
    }

    .body {
      margin-top: 16px;
    }
  }
}

@media screen and (max-width: 860px) {
  .UserType {
    margin-top: 0px;
    padding: 16px;
    display: flex;
    justify-content: space-between;

    .background-image {
      transform: translateY(0);
      margin-bottom: 0px;
      width: 114px;
      height: 129px;
    }

    .body {
      margin-top: 0px;
      width: calc(100% - 128px);

      .regency {
        font-size: 12px;
        line-height: 20px;
      }

      .hr {
        margin: 8px 0;
        width: 145px;
      }

      .iconWrapper {
        color: var(--cl-neutral-2);

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        i {
          font-size: 12px;
          margin-right: 4px;
        }
      }
    }

    &.hideDepartment {
      .background-image {
        width: 105px;
        height: 105px;
      }

      .body {
        width: calc(100% - 119px);
      }
    }
  }
}
