.NoItems {
  padding: 40px 20px;
  max-width: 664px;
  margin: 0 auto;

  .img {
    width: 375px;
    height: 303px;
    margin-bottom: 40px;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: var(--cl-neutral-1);
  }

  .content {
    font-size: 16px;
    line-height: 24px;
    color: var(--cl-neutral-2);
    margin-top: 12px;
  }

  .MuiButton-root.Button {
    margin-top: 40px;
    width: 100%;
  }
}

@media screen and (max-width: 860px) {
  .NoItems {
    padding: 0px 0px 14px;

    .img {
      width: 218px;
      height: 176px;
      margin-bottom: 24px;
    }

    .title {
      font-size: 16px;
      line-height: 24px;
    }

    .content {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .MuiButton-root.Button {
      margin-top: 24px;
    }
  }
}
