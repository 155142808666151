.TopLabel {
  margin-bottom: 10px;
}

@media screen and (max-width: 860px) {
  .TopLabel.typo-16-5 {
    font-weight: 600;
    margin-bottom: 4px;
  }
}
