@font-face {
  font-family: 'icon';
  src:  url('fonts/icon.eot?ucws4p');
  src:  url('fonts/icon.eot?ucws4p#iefix') format('embedded-opentype'),
    url('fonts/icon.ttf?ucws4p') format('truetype'),
    url('fonts/icon.woff?ucws4p') format('woff'),
    url('fonts/icon.svg?ucws4p#icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-to-top:before {
  content: "\e923";
}
.icon-engine-motor:before {
  content: "\e924";
}
.icon-checkbox-active-check:before {
  content: "\e905";
}
.icon-checkbox-active-square:before {
  content: "\e906";
}
.icon-history:before {
  content: "\e921";
}
.icon-arrow-left:before {
  content: "\e920";
}
.icon-alert-circle:before {
  content: "\e91e";
}
.icon-check-circle:before {
  content: "\e91f";
}
.icon-about:before {
  content: "\e900";
}
.icon-arrow:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-check:before {
  content: "\e903";
}
.icon-checkbox:before {
  content: "\e904";
}
.icon-close1:before {
  content: "\e907";
}
.icon-copy:before {
  content: "\e908";
}
.icon-download:before {
  content: "\e909";
}
.icon-dropdown:before {
  content: "\e90a";
}
.icon-edit:before {
  content: "\e90b";
}
.icon-email:before {
  content: "\e90c";
}
.icon-eye:before {
  content: "\e90d";
}
.icon-eye-slash:before {
  content: "\e90e";
}
.icon-filter:before {
  content: "\e90f";
}
.icon-map:before {
  content: "\e910";
}
.icon-menu1:before {
  content: "\e911";
}
.icon-minus:before {
  content: "\e912";
}
.icon-plus:before {
  content: "\e913";
}
.icon-radio:before {
  content: "\e914";
}
.icon-radio-active .path1:before {
  content: "\e915";
  color: rgb(0, 0, 0);
}
.icon-radio-active .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-search:before {
  content: "\e917";
}
.icon-shopping-cart:before {
  content: "\e918";
}
.icon-toggle-off:before {
  content: "\e919";
}
.icon-toggle-on:before {
  content: "\e91a";
}
.icon-user:before {
  content: "\e91b";
}
.icon-vision:before {
  content: "\e91c";
}
.icon-x-circle:before {
  content: "\e91d";
}
.icon-close:before {
  content: "\e964";
}
.icon-menu:before {
  content: "\e963";
}
.icon-arrow-right-circle:before {
  content: "\e962";
}
.icon-meeting-participants:before {
  content: "\e961";
}
.icon-meeting-camera-slash:before {
  content: "\e95d";
}
.icon-meeting-gear:before {
  content: "\e95e";
}
.icon-meeting-hand-paper:before {
  content: "\e95f";
}
.icon-meeting-volume-slash:before {
  content: "\e960";
}
.icon-call-decline:before {
  content: "\e955";
}
.icon-meeting-camera:before {
  content: "\e956";
}
.icon-meeting-volume-cross:before {
  content: "\e957";
}
.icon-meeting-volume-high:before {
  content: "\e958";
}
.icon-meeting-volume-low:before {
  content: "\e959";
}
.icon-meeting-volume-mute:before {
  content: "\e95a";
}
.icon-refresh-ccw:before {
  content: "\e95b";
}
.icon-send-2:before {
  content: "\e95c";
}
.icon-check-2:before {
  content: "\e953";
}
.icon-cross-2:before {
  content: "\e954";
}
.icon-alert-circle1:before {
  content: "\e946";
}
.icon-cross-circle:before {
  content: "\e947";
}
.icon-info:before {
  content: "\e948";
}
.icon-meeting-mic:before {
  content: "\e949";
}
.icon-meeting-mic-slash:before {
  content: "\e94a";
}
.icon-meeting-pause:before {
  content: "\e94b";
}
.icon-meeting-play:before {
  content: "\e94c";
}
.icon-star:before {
  content: "\e94d";
}
.icon-star-s:before {
  content: "\e94e";
}
.icon-stop-circle:before {
  content: "\e94f";
}
.icon-time:before {
  content: "\e950";
}
.icon-timer:before {
  content: "\e951";
}
.icon-watch:before {
  content: "\e952";
}
.icon-loader:before {
  content: "\e945";
}
.icon-user-square:before {
  content: "\e944";
}
.icon-angle-down:before {
  content: "\e922";
}
.icon-arrow-left1:before {
  content: "\e925";
}
.icon-arrow-right1:before {
  content: "\e926";
}
.icon-arrows-close:before {
  content: "\e927";
}
.icon-arrows-expand:before {
  content: "\e928";
}
.icon-award:before {
  content: "\e929";
}
.icon-bed-2:before {
  content: "\e92a";
}
.icon-bell:before {
  content: "\e92b";
}
.icon-book:before {
  content: "\e92c";
}
.icon-bookmark:before {
  content: "\e92d";
}
.icon-bookqueue:before {
  content: "\e92e";
}
.icon-calendar1:before {
  content: "\e92f";
}
.icon-calendar1-date:before {
  content: "\e930";
}
.icon-calendar1-event:before {
  content: "\e931";
}
.icon-calendar1-person:before {
  content: "\e932";
}
.icon-camera:before {
  content: "\e933";
}
.icon-check1:before {
  content: "\e934";
}
.icon-check-circle-r:before {
  content: "\e935";
}
.icon-check-circle-s:before {
  content: "\e936";
}
.icon-comment-2-question:before {
  content: "\e937";
}
.icon-comment-r:before {
  content: "\e938";
}
.icon-comment-s:before {
  content: "\e939";
}
.icon-creditcard:before {
  content: "\e93a";
}
.icon-cross:before {
  content: "\e93b";
}
.icon-edit-2:before {
  content: "\e93c";
}
.icon-eye1:before {
  content: "\e93d";
}
.icon-eye-slash1:before {
  content: "\e93e";
}
.icon-file-check:before {
  content: "\e93f";
}
.icon-file-download:before {
  content: "\e940";
}
.icon-file-text:before {
  content: "\e941";
}
.icon-file-upload:before {
  content: "\e942";
}
.icon-filmboard:before {
  content: "\e943";
}
.icon-folder-open:before {
  content: "\e965";
}
.icon-folder-upload:before {
  content: "\e966";
}
.icon-forward-15:before {
  content: "\e967";
}
.icon-gear-2:before {
  content: "\e968";
}
.icon-grid-16:before {
  content: "\e969";
}
.icon-heart-r:before {
  content: "\e96a";
}
.icon-heart-s:before {
  content: "\e96b";
}
.icon-image-upload:before {
  content: "\e96c";
}
.icon-link-2:before {
  content: "\e96d";
}
.icon-lock-2:before {
  content: "\e96e";
}
.icon-lock-2-opened:before {
  content: "\e96f";
}
.icon-lock-opened:before {
  content: "\e970";
}
.icon-monitor:before {
  content: "\e971";
}
.icon-more-hor:before {
  content: "\e972";
}
.icon-more-ver:before {
  content: "\e973";
}
.icon-pause1:before {
  content: "\e974";
}
.icon-pentool-2:before {
  content: "\e975";
}
.icon-phone:before {
  content: "\e976";
}
.icon-planet:before {
  content: "\e977";
}
.icon-play1:before {
  content: "\e978";
}
.icon-replay-15:before {
  content: "\e979";
}
.icon-search1:before {
  content: "\e97a";
}
.icon-send:before {
  content: "\e97b";
}
.icon-shield-check:before {
  content: "\e97c";
}
.icon-signout:before {
  content: "\e97d";
}
.icon-stack:before {
  content: "\e97e";
}
.icon-tag:before {
  content: "\e97f";
}
.icon-thermometer:before {
  content: "\e980";
}
.icon-trash:before {
  content: "\e981";
}
.icon-tv:before {
  content: "\e982";
}
.icon-undo:before {
  content: "\e983";
}
.icon-volume-high:before {
  content: "\e984";
}
.icon-volume-low:before {
  content: "\e985";
}
.icon-volume-mute:before {
  content: "\e986";
}
.icon-volume-slash:before {
  content: "\e987";
}
.icon-zoom-in:before {
  content: "\e988";
}
.icon-camera1:before {
  content: "\e991";
}
.icon-edit1:before {
  content: "\e990";
}
.icon-trash1:before {
  content: "\e98f";
}
.icon-attach:before {
  content: "\e992";
}
.icon-link:before {
  content: "\e993";
}
.icon-plus1:before {
  content: "\e994";
}
.icon-upload:before {
  content: "\e995";
}
.icon-eye2:before {
  content: "\e996";
}
.icon-eye-off:before {
  content: "\e997";
}
.icon-book1:before {
  content: "\e998";
}
.icon-email1:before {
  content: "\e999";
}
.icon-open-book:before {
  content: "\e99a";
}
.icon-chevron-down:before {
  content: "\e99b";
}
.icon-arrow-ios-left:before {
  content: "\e99c";
}
.icon-menu2:before {
  content: "\e99d";
}
.icon-close2:before {
  content: "\e99e";
}
.icon-arrow-ios-down:before {
  content: "\e99f";
}
.icon-edit-21:before {
  content: "\e9a0";
}
.icon-history1:before {
  content: "\e98e";
}
.icon-arrow-ios-right:before {
  content: "\e98d";
}
.icon-chevron-right-big:before {
  content: "\e98c";
}
.icon-department:before {
  content: "\e9a1";
}
.icon-facebook:before {
  content: "\e9a2";
}
.icon-google:before {
  content: "\e9a3";
}
.icon-research-group1:before {
  content: "\e9a4";
}
.icon-research-info:before {
  content: "\e9a5";
}
.icon-reward:before {
  content: "\e9a6";
}
.icon-scientific-paper:before {
  content: "\e9a7";
}
.icon-twitter:before {
  content: "\e9a8";
}
.icon-search2:before {
  content: "\e9a9";
}
.icon-checkmark-circle:before {
  content: "\e9aa";
}
.icon-close-circle:before {
  content: "\e9ab";
}
.icon-chevron-right:before {
  content: "\e9ac";
}
.icon-calendar1:before {
  content: "\e9ad";
}
.icon-quote:before {
  content: "\e9ae";
}
.icon-arrow-to-top1:before {
  content: "\e9af";
}
.icon-enrollment:before {
  content: "\e98b";
}
.icon-research-group:before {
  content: "\e98a";
}
.icon-loader1:before {
  content: "\e989";
}
.icon-line-chart:before {
  content: "\e9b0";
}
.icon-external-link1:before {
  content: "\e9b3";
}
