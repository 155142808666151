.SubjectItem {
  padding: 0 20px;

  & > div {
    height: 208px;
    background: var(--cl-neutral-1);
    border: 1px solid var(--cl-neutral-2);
    border-radius: 2px;
    padding: 40px;
    color: white;

    i {
      font-size: 60px;
    }

    .line-below {
      width: 60px;
      margin: 8px 0 20px;
    }
  }
}

@media screen and (max-width: 860px) {
  .SubjectItem {
    padding: 0 12px;

    & > div {
      width: 180px;
      height: 112px;
      padding: 16px;

      i {
        font-size: 40px;
      }

      .line-below {
        display: none;
      }

      .typo-24-6 {
        margin-top: 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
