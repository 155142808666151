.QuestionItem {
  background-color: white;
  padding: 20px;

  &-btn {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    &.active {
      color: var(--cl-primary);

      i {
        color: var(--cl-primary);
      }
    }

    i {
      font-size: 24px;
      margin-left: 16px;
      height: 24px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--cl-primary);

        i {
          color: var(--cl-primary);
        }
      }
    }
  }

  .hr {
    margin: 20px 0 12px;
    border-top: 1px solid var(--cl-neutral-4);
  }

  &-answer {
    font-size: 14px;
    line-height: 20px;
    color: var(--cl-neutral-1);
  }
}

@media screen and (max-width: 860px) {
  .QuestionItem {
    padding: 16px;

    &-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      i {
        font-size: 20px;
        height: 20px;
      }
    }

    &-answer {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
