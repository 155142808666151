.PopupAttachedFile {
  &-item {
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &__block {
      & > div {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        i {
          margin-right: 6px;
          color: #757575;
          width: 16px;
        }
      }
    }
  }
}
