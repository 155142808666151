.Partners {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -8px;

  .col {
    width: 20%;
    margin-bottom: 40px;
    padding: 0 8px;

    a {
      display: flex;

      img {
        transform: scale(1.01);
        transition: transform 0.3s ease;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      & > a {
        transition: background-color 0.3s ease;

        &:hover {
          img {
            transform: scale(1.2);
            z-index: 1;
          }
        }
      }
    }

    img {
      width: 100%;
      height: 134px;
      cursor: pointer;
      object-fit: contain;
    }
  }
}

.PartnersWrapper {
  .gap {
    height: 60px;
  }
}

@media screen and (max-width: 860px) {
  .Partners {
    .col {
      width: 33.33333%;
      margin-bottom: 24px;

      img {
        height: 80px;
      }
    }
  }

  .PartnersWrapper {
    .gap {
      height: 0;
    }
  }
}
