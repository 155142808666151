.MoreNews {
  &-row {
    display: flex;
    margin: 0 -20px;
    flex-wrap: wrap;

    .col {
      width: 33.33333%;
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 860px) {
  .MoreNews {
    &-row {
      .col {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }
}
