.ResearchDirectionItem {
  padding: 0 20px;

  & > div {
    background: var(--cl-primary);
    border-radius: 4px;
    padding: 40px;
    color: white;

    i {
      font-size: 80px;
    }

    .line-below {
      width: 60px;
      margin: 20px 0 40px;
      border-radius: 2px;
      height: 6px;
    }
  }
}

@media screen and (max-width: 860px) {
  .ResearchDirectionItem {
    padding: 0 44px;

    & > div {
      border-radius: 2px;
      padding: 16px;

      i {
        font-size: 40px;
      }

      .line-below {
        width: 24px;
        height: 2px;
        margin: 8px 0 16px;
        border-radius: 0px;
      }
    }
  }
}
