.Chart-content-main {
  position: absolute;
  width: 100%;
  left: 0;
  height: calc(100% + 7px);
  top: 35px;
  overflow-y: visible !important;
  overflow: scroll;
  // padding-top: 250px;

  &.hideScroll::-webkit-scrollbar {
    display: none;
  }
  &.hideScroll {
    top: 35px;
    height: 100%;
    overflow-x: visible;
  }

  &:not(.mobile) {
    &::-webkit-scrollbar {
      height: 7px;
      background-color: #ffff;
      &:hover {
        cursor: pointer;
      }
    }
    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #ffff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #c4bebe;
    }
  }
}

.Chart {
  &-wrapper {
    padding: 52px 0 76px 60px;

    & > div {
      position: relative;
    }
  }

  &-lines {
    border-left: 1px solid var(--cl-neutral-4);
    border-right: 1px solid var(--cl-neutral-4);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    & > div {
      position: relative;
      border-bottom: 1px solid var(--cl-neutral-4);

      & > div {
        position: absolute;
        left: -60px;
        transform: translateY(-50%);
      }
    }
  }

  &-content {
    top: -36px;
    left: 0;
    position: absolute;
    overflow-y: visible;
    // display: flex;
    // flex-direction: row;
  }

  &-columnWrapper {
    position: relative;
    transition: opacity 0.3s ease;
    margin: 0 20px;

    &__block {
      position: absolute;
      width: 100%;
      height: 60px;
      bottom: -40px;
    }

    &__label {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 100%);
      bottom: -16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &-column {
    border-radius: 4px;
    width: 40px;
    margin: 0 4px;
    transition: opacity 0.3s ease;

    &.small {
      width: 32px;
      margin: 0 2px;
    }
  }

  &-glossary {
    margin-bottom: 34px;
    margin-left: 60px;
    &__item {
      margin-bottom: 24px;
      margin-right: 32px;

      max-width: 100%;
    }

    &__block {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      border-radius: 4px;
      margin-right: 16px;
    }
  }

  &-popper {
    &__paper.MuiPaper-root {
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }

    &__content {
      padding: 16px;
    }

    &__arrow {
      position: absolute;
      background-image: url('../../../assets/images/arrow.svg');
      width: 50px;
      height: 46px;
      bottom: -30px;
      transform: translateX(-50%);
      left: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__glossary {
      &:not(:last-child) {
        margin-bottom: 18px;
      }

      & > div {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        margin-right: 8px;
      }
    }
  }
}

.Chart-small {
  .Chart {
    &-glossary {
      &__item {
        margin-bottom: 0;
        margin-right: 0;

        max-width: 100%;

        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .Chart-content-main {
    position: absolute;
    width: 100%;
    top: 52px;
    left: 0;
    height: 100%;
  }
  .Chart {
    &-column {
      width: 32px;
      margin: 0 4px;
      border-radius: 4px;

      &.small {
        width: 20px;
        margin: 0 2px;
      }
    }

    &-lines {
      & > div {
        & > div {
          left: -36px;
        }
      }
    }
  }

  .Chart-mobile {
    .Chart {
      &-wrapper {
        padding: 36px 0 70px 36px;

        & > div {
          position: relative;
        }

        &:not(:first-child) {
          padding: 0px 0 70px 36px;
        }
      }

      &-column {
        width: 16px;
        margin: 0 4px;
        border-radius: 2px;
      }

      &-glossary {
        flex-direction: column;
        margin-bottom: 27px;
        margin-left: 36px;

        &__item {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          margin-bottom: 16px;
          margin-right: 0px;
          max-width: 100%;
        }

        &__block {
          width: 12px;
          height: 12px;
          min-width: 12px;
          min-height: 12px;
          border-radius: 2px;
          margin-right: 8px;
        }
      }
    }
  }

  .Chart-small {
    .Chart {
      &-glossary {
        flex-direction: row;

        &__item {
          margin-bottom: 0;
          margin-right: 0;

          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .Chart-content-main {
    position: absolute;
    width: 100%;
    top: 36px;
    left: 0;
    height: 100%;
  }
  .Chart {
    &-popper {
      &__content {
        padding: 12px;
      }

      &__glossary {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        & > div {
          width: 12px;
          height: 12px;
          border-radius: 2px;
        }
      }
    }
  }
}
