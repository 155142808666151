.Introductions {
  &Missions {
    &Slider {
      .slick-list {
        margin: 0 -20px;
      }

      .col {
        padding: 0 20px;
      }

      .slick {
        &-dots {
          bottom: -24px;

          & li {
            margin: 0 2px;
            padding: 0;
            height: 0px;
            width: 24px;

            &.slick-active {
              & button:before {
                background: #242056;
              }
            }

            & button {
              padding: 0;
              width: 100%;
              height: 4px;
              position: relative;

              &:before {
                content: '';
                width: 100%;
                height: 100%;
                background: #e6e6e6;
                opacity: 1;
                border-radius: 2px;
              }
            }
          }
          @media screen and (max-width: 860px) {
            & li {
              height: 0px;
              width: 11.43px;
              & button {
                width: 100%;
                height: 2px;
              }
            }
          }
        }
      }

      &.dark {
        .slick {
          &-dots {
            & li {
              &.slick-active {
                & button:before {
                  background: #ffffff !important;
                }
              }

              & button {
                &:before {
                  background: #727779 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
