.MissionItem {
  &-banner {
    height: 480px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    .title {
      position: absolute;
      bottom: 0;
      height: 92px;
      left: 0;
      background: rgba(80, 75, 119, 0.85);
      color: white;
      padding: 0 16px;
      text-align: center;
    }
  }

  .content {
    margin-top: 40px;
    color: var(--cl-neutral-1);

    &.dark {
      color: white;
    }
  }
}

@media screen and (max-width: 860px) {
  .MissionItem {
    &-banner {
      height: 304px;
      border-radius: 2px;

      .title {
        height: 72px;
      }
    }

    .content {
      margin-top: 16px;
    }
  }
}
