.Tabs {
  border-bottom: 1px solid var(--cl-neutral-4);
  padding-top: 30px;

  &-indicator {
    position: absolute;
    bottom: 0;
    height: 2px;
    padding: 0 14px;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    & > div {
      width: 100%;
      height: 100%;
      background-color: var(--cl-semantic-1);
    }
  }

  .menu-wrapper {
    margin: 0 -14px;

    .menu-wrapper--inner {
      display: flex;

      &.rtl {
        flex-direction: row-reverse;
      }

      .menu-item-wrapper {
        padding: 0 14px;
        outline: none;

        .MuiButtonBase-root {
          text-transform: uppercase;
          border-radius: 0;
          padding: 10px 0;
          min-width: 0;
          min-height: 0;
          font-size: 16px;
          line-height: 24px;
          color: var(--cl-neutral-2);
          font-weight: 400;
          height: auto;

          &:hover {
            color: var(--cl-secondary-1);
            background-color: transparent;
          }
        }

        &.active {
          .MuiButtonBase-root {
            font-weight: 600;
            color: var(--cl-secondary-1);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .Tabs {
    .menu-wrapper {
      .menu-wrapper--inner {
        .menu-item-wrapper {
          .MuiButtonBase-root {
            padding: 4px 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
          }

          &.active {
            .MuiButtonBase-root {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.MuiButton-root.ButtonArrow-menu {
  background-color: white;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--cl-primary);
  border-radius: 2px;
  color: var(--cl-primary);
  font-size: 30px;
  transition: all 0.3s ease;

  &::before {
    display: none;
  }

  &:focus {
    color: var(--cl-primary);
  }

  &:hover {
    color: white;
    border: 1px solid var(--cl-primary);
    background-color: var(--cl-primary);
  }
  &.left {
    transform: rotate(180deg);
  }

  @media not all and (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: white;
      border: 1px solid var(--cl-primary);
      color: var(--cl-primary);
    }
  }
}

@media screen and (max-width: 1500px) {
  .MuiButton-root.ButtonArrow-menu {
    min-width: 30px;
    width: 30px;
    height: 30px;
    font-size: 24px;
    padding: 0;
  }
}

@media screen and (max-width: 860px) {
  .MuiButton-root.ButtonArrow-menu {
    min-width: 24px;
    width: 24px;
    height: 24px;
    font-size: 20px;
    padding: 0;
  }
}

@media screen and (max-width: 860px) {
  .arrows_wrapper {
    display: none;
  }
}
