.TrainingItem {
  height: 100%;
  display: flex;
  flex-direction: column;

  &-banner {
    display: block;
    width: 100%;
    border-radius: 4px;
    height: 304px;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: background-color 0.3s ease;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &::after {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  &-body {
    padding: 24px;
    flex-grow: 1;
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    &__title {
      display: block;
      color: var(--cl-neutral-1);
      margin-bottom: 16px;
    }

    &__content {
      display: block;
      color: var(--cl-neutral-2);
      margin-bottom: 40px;
    }

    &__more {
      display: flex;
      align-items: center;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          span {
            text-decoration: underline;
          }
        }
      }

      i {
        font-size: 24px;
        margin-left: 8px;
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .TrainingItem {
    &-banner {
      height: 236px;
    }

    &-body {
      padding: 16px 0 0;

      &__title {
        margin-bottom: 4px;
      }

      &__content {
        margin-bottom: 12px;
      }

      &__more {
        i {
          font-size: 16px;
        }
      }
    }
  }
}
