.EnrollmentItem {
  display: flex;

  .banner {
    border-radius: 2px;
    width: 198px;
    height: 168px;
    margin-right: 24px;
  }

  .body {
    width: calc(100% - 222px);
  }

  .title {
    color: var(--cl-neutral-1);
    transition: color 0.3s ease;
    margin-bottom: 12px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .title {
        color: var(--cl-semantic-1) !important;
      }
    }
  }

  .content {
    color: var(--cl-neutral-2);
  }

  .label-icon-wrapper {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
    }
    .view {
      padding-right: 14px;
    }
    .view,
    .comment {
      i {
        margin-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .EnrollmentItem {
    .banner {
      width: 103px;
      height: 103px;
      margin-right: 16px;
    }

    .body {
      width: calc(100% - 119px);
    }

    .title {
      margin-bottom: 7px;
    }

    .label-icon-wrapper {
      margin-bottom: 16px;
    }
  }
}
