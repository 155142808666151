// toastWidth 360 - offset 30
.toast-container {
  box-sizing: border-box;
  position: fixed;
  bottom: 30px; // 20
  right: -372px; // - toastWidth
  width: 784px; // 2 * toastWidth + offset
  z-index: 9999;
  display: flex;
  flex-direction: column-reverse;

  .toast {
    width: 372px;
    margin-left: 412px; // toastWidth + offset
    transition: all 0.4s ease;
    background-color: #fff;
    display: flex;
    // box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 7px 35px rgba(0, 0, 0, 0.15);
    overflow: hidden;

    &.slide-in {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 480px) {
    right: calc(40px - 100vw);
    width: calc(200vw - 60px);

    .toast {
      width: calc(100vw - 40px);
      margin-left: calc(100vw - 20px);
    }
  }
}
