.UserDetailInfo-avatar {
  position: relative;

  .overlay {
    position: absolute;
    inset: 0;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }

  &.loading {
    .UserDetailInfo-uploadAvatar {
      cursor: default;

      &:hover {
        background-color: white;
      }
    }

    .overlay {
      background-color: #7775;
      border-radius: inherit;
    }
  }
}

.UserDetailInfo {
  .avatar {
    width: 229px;
    height: 264px;
    border-radius: 2px;
  }

  .info {
    width: calc(100% - 269px);
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;

    & > div {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      span {
        display: inline-block;

        &:first-child {
          width: 203px;
          font-weight: 500;
        }

        &:last-child {
          font-weight: 600;
          text-overflow: ellipsis;
          overflow: hidden;
          width: calc(100% - 203px);
        }
      }
    }
  }

  .biography {
    margin-top: 40px;
  }

  &-mobile {
    .avatar {
      width: 180px;
      height: 206px;
    }

    .fullName {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
    }

    .regency {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }

    .mainInfo {
      background: var(--cl-neutral-5);
      border-radius: 2px;
      font-size: 12px;
      line-height: 20px;
      white-space: nowrap;

      & > div {
        display: flex;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        span {
          display: inline-block;

          &:first-child {
            width: calc(45% - 16px);
            font-weight: 500;
            margin-right: 16px;
            text-align: right;
          }

          &:last-child {
            font-weight: 600;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 55%;
          }
        }
      }
    }

    .biographyWrapper {
      margin: 24px -24px 0;
      background-color: var(--cl-primary);

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: white;
      }

      .biography {
        margin-top: 0;
        color: white;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  &-uploadAvatar {
    position: absolute;
    bottom: 12px;
    right: 12px;
    background-color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transition: background-color 0.3s ease;
    z-index: 1;

    i {
      font-size: 20px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--cl-accent-1);
      }
    }
  }
}

.UserDetailResearchDirections {
  padding: 40px 0;
  background: var(--cl-neutral-5);
  margin-top: 80px;

  .MuiButtonBase-root{
    margin-left: -10px;
  }

  &-slider {
    margin: 0 -20px;
  }

  &-arrow {
    &.slick-prev {
      left: -72px;
    }

    &.slick-next {
      right: -72px;
    }
  }
}

.ck-body-wrapper {
  .ck.ck-form__row.ck-table-form__border-row {
    display: none !important;
  }
}
.ck-editor__editable {
  max-height: 50vh !important;
  min-height: 50vh !important;
  @media screen and (max-width: 860px) {
    max-height: 30vh !important;
    min-height: 30vh !important;
  }
}

.ck-editor__editable:not(.ck-read-only) .ck-media__wrapper > :not(.ck-media__placeholder) {
  pointer-events: initial !important;
}

.ck.ck-balloon-panel.ck-balloon-panel_arrow_nw.ck-balloon-panel_visible.ck-balloon-panel_with-arrow {
  z-index: 9999999 !important; // Put a higher value that your MUI Dialog (generaly 1300)
}

.ck.ck-balloon-panel.ck-balloon-panel_visible.ck-balloon-panel_with-arrow {
  z-index: 9999999 !important;
}

.ModalCreateUpdate {
  .AdminEditor {
    @media screen and (max-width: 860px) {
      min-height: calc(41vh + 42px);
    }
  }
}

.UserDetailResearches {
  .banner {
    background-image: url('../../../assets/images/common/researches.jpg');
    padding: 96px 24px;
    text-transform: uppercase;
    color: white;
  }

  &-row {
    display: flex;

    .col-left {
      width: 76.56%;
    }

    .col-right {
      width: 23.44%;
    }

    &.header {
      background-color: var(--cl-primary);
      color: white;
      padding: 12px;
    }

    &.body {
      background: var(--cl-neutral-5);

      .col-right {
        padding: 20px 24px;
      }
    }
  }
}

.MyScientificPapersComponent {
  // .banner {
  //   background-image: url('../../../assets/images/common/scientific_papers.jpg');
  //   padding: 96px 24px;
  //   text-transform: uppercase;
  //   color: white;
  // }

  &-item {
    &.body {
      background: var(--cl-neutral-5);
    }
  }
  & .ScientificPaperAttachedFile {
    padding-left: 54px;
  }

  &-row {
    display: flex;

    .col-left {
      width: 70%;
    }

    .col-center {
      width: 11.72%;
    }

    .col-right {
      width: 18.28%;

      i {
        font-size: 24px;
        color: var(--cl-neutral-1);
        margin: 0 12px;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: var(--cl-semantic-1);
        }
      }
    }

    &.header {
      background-color: var(--cl-primary);
      color: white;

      .col-left,
      .col-center,
      .col-right {
        padding: 12px;
      }
    }

    &.body {
      .col-right {
        padding: 8px;
      }
    }
  }
}

.UpdateResearchDirections {
  &-label {
    padding: 12px 0 12px;
  }
}

@media screen and (max-width: 1500px) {
  .UserDetailResearchDirections {
    &-arrow {
      &.slick-prev {
        left: -48px;
      }

      &.slick-next {
        right: -48px;
      }
    }

    &-slider {
      margin: 0 48px;
    }
  }
}

@media screen and (max-width: 860px) {
  .UserDetailInfo {
    &-uploadAvatar {
      bottom: 8px;
      right: 8px;
      width: 28px;
      height: 28px;

      i {
        font-size: 14px;
      }
    }
  }

  .UserDetailResearchDirections {
    padding: 24px 0;
    margin-top: 40px;

    &-arrow {
      &.slick-prev {
        left: 0px;
      }

      &.slick-next {
        right: 0px;
      }
    }

    &-slider {
      margin: 0 0;
    }
  }

  .UserDetailResearches {
    .banner {
      padding: 36px 24px;
    }

    &-row {
      .col-left {
        width: 79.82%;
      }

      .col-right {
        width: 20.18%;
      }

      &.header {
        padding: 8px 2px;
      }

      &.body {
        .col-right {
          padding: 12px 4px;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
        }
      }
    }
  }
  .UserDetailEditContent {
    &-row {
      .col-left {
        width: 78.29%;
      }

      .col-right {
        width: 21.71%;
        flex-direction: column;

        i {
          font-size: 20px !important;
          margin: 6px 0 !important;
        }
      }
    }
  }

  .UpdateResearchDirections {
    &-label {
      padding: 0 0 8px;
    }
  }
}

.main_researchs {
  margin: 20px 0;
}
.UserDetailEditContent {
  // padding: 40px 0;
  margin-top: 80px;
  @media screen and (max-width: 860px) {
    margin-top: 40px;
  }
  &-row {
    display: flex;
    align-items: flex-start;

    .col-left {
      width: 82%;
    }

    .col-right {
      width: 18%;

      i {
        font-size: 24px;
        color: var(--cl-neutral-1);
        margin: 0 12px;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: var(--cl-semantic-1);
        }
      }
    }

    &.header {
      background-color: var(--cl-primary);
      color: white;

      .col-left,
      .col-center,
      .col-right {
        padding: 12px;
      }
    }

    &.body {
      .col-right {
        padding: 8px;
      }
    }
  }
}
