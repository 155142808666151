.SelectPaper-search {
  padding: 12px;
}

.SelectPaper-paper {
  .simplebar-track {
    .simplebar-scrollbar {
      &::before {
        background-color: #929394af;
      }
    }

    &.simplebar-vertical {
      width: 10px;
    }
  }
}

.Select {
  &-item {
    height: 36px;
    font-size: 14px !important;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 12px;
    &.noWhireSpace {
      overflow: unset !important;
      text-overflow: unset !important;
      white-space: pre-wrap !important;
    }
    &.active {
      background-color: var(--cl-primary-20) !important;
      &.highlight {
        background-color: var(--cl-accent-1) !important;
      }
    }

    &.remove {
      background-color: #ededed;
      color: #757575;

      &:hover {
        background-color: #e6e6e6;
      }
    }
  }

  &-arrow {
    color: rgba(0, 0, 0, 0.54);
    margin-right: -7px;

    &.focus {
      transform: rotate(180deg);
    }
  }

  &-readOnly {
    .MuiOutlinedInput-root {
      cursor: pointer !important;

      input {
        cursor: pointer !important;
      }
    }
  }
}
