.InputSearch {
  .MuiInputBase-adornedStart {
    padding-left: 10px !important;
  }

  .icon-search {
    font-size: 20px;
  }
}

.MuiInputBase-formControl.MuiInputBase-sizeSmall {
  font-size: 15px;
}

.xs {
  input {
    padding: 6px 14px;
  }
}
