.HomeSlider {
  &-container {
    & .slick {
      &-slide {
        & > div {
          display: flex;
        }
      }

      &-dots {
        bottom: 0;

        & li {
          margin: 0 6px;
          padding: 0;
          height: 34px;

          &.slick-active {
            & button:before {
              background: white;
            }
          }

          & button {
            padding: 0;
            width: 24px;
            height: 5px;
            position: relative;

            &:before {
              content: '';
              width: 100%;
              height: 5px;
              background: var(--cl-neutral-2);
              opacity: 1;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
}

.HomeSliderItem {
  &-container {
    height: 687px;
  }

  &-mainContainer {
    display: inline-flex !important;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    position: relative;
    padding-top: 120px;

    .title {
      font-weight: 600;
      font-size: 46px;
      line-height: 56px;
    }

    .content {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-top: 22px;
    }
  }
}

.HomeNews {
  &-row {
    display: flex;
    margin: 0 -20px;
    flex-wrap: wrap;

    .col {
      width: 50%;
      padding: 0 20px;
      margin-bottom: 24px;
    }
  }
}

.HomeTraining {
  &-row {
    display: flex;
    margin: 0 -20px;
    flex-wrap: wrap;

    .col {
      width: 33.333333%;
      padding: 0 20px;
      margin-bottom: 24px;
    }
  }
}

.HomeAboutUs {
  &-arrow {
    &.slick-prev {
      left: -40px;
    }

    &.slick-next {
      right: -40px;
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;

      & > div {
        height: 100%;

        & > div {
          height: 100%;
        }
      }
    }
  }

  &-itemContainer {
    height: 100%;
    padding-top: 135px;
    padding-bottom: 24px;
  }
}

.HomeProducts {
  .HomeProducts-container {
    margin: 0px auto 80px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &-background {
    background: #504b77;
    border-radius: 4px;
    max-width: 1478px;
    margin: 0 auto;
  }

  &-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .col {
      width: 33.333333%;
    }
  }
}

@media screen and (max-width: 1440px) {
  .HomeAboutUs {
    &-arrow {
      &.slick-prev {
        left: 0px;
      }

      &.slick-next {
        right: 0px;
      }
    }
  }

  .HomeOverview {
    &-row {
      .col {
        & > div {
          padding: 70px 36px 0;

          .icon {
            position: absolute;
            width: 72px;
            height: 72px;
            border-radius: 4px 4px 4px 24px;

            i {
              font-size: 40px;
            }
          }

          .count {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .HomeNews {
    &-row {
      .col {
        width: 100%;
      }
    }
  }

  .HomeSlider {
    &-container {
      & .slick {
        &-dots {
          & li {
            margin: 0 2px;
            width: 11.5px;
            height: 12px;

            & button {
              width: 11.5px;
              height: 2px;

              &:before {
                width: 100%;
                height: 2px;
              }
            }
          }
        }
      }
    }
  }

  .HomeSliderItem {
    &-container {
      height: 222px;
    }

    &-mainContainer {
      padding: 72px 16px 16px;
      color: white;
      justify-content: left;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        max-width: 350px;
      }

      .content {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 8px;
        max-width: 350px;
      }
    }
  }

  .HomeAboutUs {
    &-arrow {
      &.slick-prev {
        left: 0px;
      }

      &.slick-next {
        right: 0px;
      }
    }

    &-itemContainer {
      padding: 76px 40px 24px;
    }
  }

  .HomeProducts {
    .HomeProducts-container {
      margin: 0px auto 40px;
      padding-top: 24px;
      padding-bottom: 0px;
    }

    &-background {
      border-radius: 0px;
    }

    &-row {
      .col {
        margin-bottom: 24px;
        width: 100%;
      }
    }
  }

  .HomeTraining {
    &-row {
      .col {
        width: 100%;
      }
    }
  }
}
