.ProductItem {
  &-notFull {
    display: block;
    background: var(--cl-primary);
    border: 1px solid var(--cl-secondary-1);
    padding: 32px;
    height: 100%;
    transition: background-color 0.3s ease;

    .background-image {
      width: 176px;
      height: 176px;
      border-radius: 4px;
      margin-bottom: 20px;
    }

    .title {
      margin-top: 40px;
      color: #ffffff;
      margin-bottom: 24px;
      max-width: 337px;
      height: 72px;
    }

    .content {
      color: #ffffff;
      opacity: 0.6;
      max-width: 337px;
    }

    &:hover {
      background: var(--cl-neutral-1);
    }
  }

  &-full {
    border-radius: 0px 0px 4px 4px;
    overflow: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    background-repeat: repeat !important;
    background-position: center !important;
    background-size: cover !important;

    & > div {
      position: relative;
      padding: 40px;
    }

    .title {
      color: #ffffff;
      margin-bottom: 16px;
      max-width: 700px;
    }

    .content {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--cl-neutral-4);
      max-width: 700px;
    }

    button {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 341px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .ProductItem {
    &-notFull {
      .title {
        height: 58px;
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .ProductItem {
    &-notFull {
      padding: 24px;

      .background-image {
        width: 100%;
        height: 279px;
        border-radius: 2px;
        margin-bottom: 12px;
      }

      .title {
        margin-top: 16px;
        margin-bottom: 16px;
        height: auto;
        max-width: fit-content;
      }

      .content {
        max-width: fit-content;
      }

      &:hover {
        background: var(--cl-neutral-1);
      }
    }

    &-full {
      border-radius: 0px 0px 2px 2px;

      & > div {
        padding: 24px;
      }

      .content {
        font-size: 12px;
        line-height: 20px;
        max-width: 380px;
      }

      button {
        position: relative;
        width: auto;
        width: 181px;
        margin-top: 24px !important;
      }
    }
  }
}
