.ScientificPapers {
  .banner {
    background-image: url('../../../assets/images/common/scientific_papers.jpg');
    padding: 96px 24px;
    text-transform: uppercase;
    color: white;
  }
}

.MyScientificPapersComponent {
  // .banner {
  //   background-image: url('../../../assets/images/common/scientific_papers.jpg');
  //   padding: 96px 24px;
  //   text-transform: uppercase;
  //   color: white;
  // }

  &-item {
    &.body {
      background: var(--cl-neutral-5);
    }
  }
  & .ScientificPaperAttachedFile {
    padding-left: 54px;
  }

  &-row {
    display: flex;

    .col-left {
      width: 70%;
    }

    .col-center {
      width: 11.72%;
    }

    .col-right {
      width: 18.28%;

      i {
        font-size: 24px;
        color: var(--cl-neutral-1);
        margin: 0 12px;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: var(--cl-semantic-1);
        }
      }
    }

    &.header {
      background-color: var(--cl-primary);
      color: white;

      .col-left,
      .col-center,
      .col-right {
        padding: 12px;
      }
    }

    &.body {
      .col-right {
        padding: 8px;
      }
    }
  }
}

.CrawlScientificPapers.Modal {
  .MuiDialogTitle-root {
    margin-bottom: 24px;
  }
}

.InputAuthor {
  border-radius: 2px;
  position: relative;
  padding: 4px 12px;
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s ease;

  .border {
    position: absolute;
    border-radius: inherit;
    border: 1px solid var(--cl-neutral-3);
    inset: 0;
    pointer-events: none;
    transition: inherit;
  }

  &:hover {
    .border {
      border-color: var(--cl-neutral-2);
    }
  }

  &.focus {
    .border {
      border-color: var(--cl-primary);
    }
  }

  &.error {
    .border {
      border-color: var(--cl-error);
    }
  }

  &-authorName {
    padding: 4px 10px 4px 13px;
    background: var(--cl-neutral-5);
    border: 1px solid var(--cl-neutral-4);
    border-radius: 2px;
    margin: 4px;
    display: flex;
    align-items: center;
    color: var(--cl-neutral-1);
    overflow: hidden;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }

    .icon-close {
      cursor: pointer;
      font-size: 12px;
      margin-left: 8px;
    }
  }

  input {
    border: none !important;
    outline: none !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--cl-neutral-1);
    height: 40px;
    padding: 0 4px;
    flex-grow: 1;
  }
}

@media screen and (max-width: 860px) {
  .ScientificPapers {
    .banner {
      padding: 36px 24px;
    }
  }

  .MyScientificPapersComponent {
    .banner {
      padding: 36px 24px;
    }

    &-item {
      & .ScientificPaperAttachedFile {
        padding-left: 30px;
      }
    }

    &-row {
      .col-left {
        width: 78.29%;
      }

      .col-right {
        width: 21.71%;
        flex-direction: column;

        i {
          font-size: 20px;
          margin: 6px 0;
        }
      }

      &.header {
        .col-left,
        .col-right {
          padding: 8px 4px;
        }
      }
    }
  }

  .InputAuthor {
    &-authorName {
      padding: 3px 6px 3px 10px;

      span {
        font-size: 11px;
        line-height: 16px;
      }

      .icon-close {
        font-size: 12px;
      }
    }

    input {
      height: 32px;
    }
  }
}
