.OfferCooperate {
  background: var(--cl-primary);
  padding: 40px;
  margin: 40px 0 0px;
  border-radius: 2px;

  &.noMarginTop {
    margin: -118px 0 0;
  }

  .header {
    margin-bottom: 40px;
    color: white;
  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .content {
      color: var(--cl-neutral-5);
      max-width: 555px;
    }
  }
}

.ModalCooperate {
  &-note {
    margin: -8px 0 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 860px) {
  .OfferCooperate {
    padding: 24px;
    margin: 16px -24px 0px;

    &.noMarginTop {
      margin: -2px -24px 0 -24px;
    }

    .header {
      margin-bottom: 12px;
    }

    .body {
      flex-direction: column;
      align-items: flex-start;

      .content {
        margin-bottom: 24px;
      }
    }
  }

  .ModalCooperate {
    &-note {
      margin: 12px 0 16px;
      font-size: 12px;
    }
  }
}
