.d-b {
  display: block;
}

.d-f {
  display: flex !important;
}

.jc-sa {
  justify-content: space-around;
}

.jc-sb {
  justify-content: space-between;
}

.jc-se {
  justify-content: space-evenly;
}

.jc-c {
  justify-content: center;
}

.jc-e {
  justify-content: end;
}

.ai-fs {
  align-items: flex-start;
}

.fd-c {
  flex-direction: column;
}

.fd-rr {
  flex-direction: row-reverse;
}

.fd-cr {
  flex-direction: column-reverse;
}

.ai-s {
  align-items: flex-start;
}

.ai-c {
  align-items: center;
}

.ai-e {
  align-items: flex-end;
}

.fw-w {
  flex-wrap: wrap;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.pt-0 {
  padding-top: 0;
}

.p-2 {
  padding: 16px;
}

.pt-1 {
  padding-top: 8px;
}

.pb-1 {
  padding-bottom: 8px;
}

.py-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.mx-2 {
  margin-left: 16px;
  margin-right: 16px;
}

.my-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-5 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mt-1 {
  margin-top: 8px;
}

.mr-1 {
  margin-right: 8px;
}

.mb-1 {
  margin-bottom: 8px;
}

.ml-1 {
  margin-left: 8px;
}

.ml-15 {
  margin-left: 12px;
}

.mt-2 {
  margin-top: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.ml-2 {
  margin-left: 16px;
}

.mr-2 {
  margin-right: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mb-3 {
  margin-bottom: 24px;
}

.ml-3 {
  margin-left: 24px;
}

.mr-3 {
  margin-right: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.ml-4 {
  margin-left: 32px;
}

.mt-5 {
  margin-top: 40px;
}

.mb-5 {
  margin-bottom: 40px;
}

.px-1 {
  padding-left: 8px;
  padding-right: 8px;
}

.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.px-2 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.pt-5 {
  padding-top: 40px;
}

.pb-5 {
  padding-bottom: 40px;
}

.py-4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-r {
  text-align: right;
}

.c-p {
  cursor: pointer;
}

.maxw-500 {
  max-width: 500px;
}

.underline {
  text-decoration: underline;
}

.b-w {
  word-wrap: break-word;
}

iframe[style*='z-index: 2147483647;'] {
  z-index: -1 !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;

  .col-3,
  .col-4,
  .col-6,
  .col-8 {
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 12px;
  }

  .col-3 {
    width: 25%;
  }

  .col-4 {
    width: 33.3333%;
  }

  .col-6 {
    width: 50%;
  }

  .col-8 {
    width: 66.6667%;
  }

  &.row-20 {
    margin: 0 -20px;

    .col-4 {
      width: 33.33333333%;
      padding: 0 20px;
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 860px) {
  .row {
    .col-3,
    .col-4 {
      width: 50%;
    }

    .col-6,
    .col-8 {
      width: 100%;
    }

    &.row-20 {
      .col-4 {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  .mb-3 {
    &.mb-3-2 {
      margin-bottom: 16px;
    }
  }

  .mt-5 {
    &.mt-5-3 {
      margin-top: 24px;
    }
  }

  .mb-5 {
    &.mb-5-3 {
      margin-bottom: 24px;
    }
  }

  .pb-5 {
    &.pb-5-3 {
      padding-bottom: 14px;
    }
  }

  .mt-80 {
    margin-top: 40px;

    &.mt-80-20 {
      margin-top: 20px;
    }
  }

  .mb-80 {
    margin-bottom: 40px;
  }

  .pt-80 {
    padding-top: 40px;

    &.pt-80-20 {
      padding-top: 20px;
    }
  }

  .py-80 {
    padding-top: 40px;
    padding-bottom: 40px;

    &.py-80-24 {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  .mb-40 {
    &.mb-40-24 {
      margin-bottom: 24px;
    }
  }

  .py-4 {
    &.py-4-3 {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

@media screen and (max-width: 600px) {
  .row {
    .col-3,
    .col-4 {
      width: 100%;
    }
  }
}
