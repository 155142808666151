.MenuItem {
  position: relative;
  z-index: 1;
  color: var(--cl-neutral-1);

  &-active,
  &:hover {
    color: var(--cl-semantic-1);
  }

  &-item {
    background-color: white;
    display: none;
    position: absolute;
    top: 100%;
    padding: 16px;
    width: 316px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 2px;

    &.isSubMenu {
      top: -16px;
      left: calc(100% + 16px);
    }

    &.show {
      display: block;
    }

    i {
      font-size: 20px;
    }
  }

  &.isSubMenu {
    border-radius: 2px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--cl-neutral-1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &::after {
      position: absolute;
      left: calc(100% - 24px);
      width: 48px;
      height: 100%;
      content: '';
      z-index: 2;
    }

    &:hover {
      background: var(--cl-accent-1);
    }
  }
}

.MenuItemCollapse {
  color: var(--cl-neutral-1);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  &.isSubMenu {
    margin-bottom: 12px;
    display: flex;

    .title {
      cursor: pointer;
      color: var(--cl-neutral-2);
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      &.active {
        color: var(--cl-neutral-1);
      }
    }
  }

  i {
    font-size: 24px;
    height: 24px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--cl-semantic-1);
    }
  }

  &-active {
    color: var(--cl-semantic-1);
  }
}
