.AboutUsItem {
  height: 100%;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 131px 50px 51px;

  &-avatar {
    position: absolute;
    width: 188px;
    height: 188px;
    background: #c4c4c4;
    border-radius: 50%;
    top: 0;
    transform: translate(0, -50%);
    border: 2px solid var(--cl-neutral-2);
    object-fit: cover;
  }

  &-body {
    &__author {
      color: var(--cl-neutral-1);
      margin-bottom: 4px;
      padding: 0 72px;
    }

    &__authorInfo {
      color: var(--cl-neutral-2);
      padding: 0 72px;
    }

    &__contentWrapper {
      padding: 43px 76px 0;
    }

    &__content {
      color: var(--cl-neutral-2);
      position: relative;

      span {
        font-style: italic;
      }

      .quotes {
        position: absolute;
        font-size: 42px;
        color: var(--cl-semantic-1);

        &:first-child {
          left: -20px;
          top: 5px;
          transform: translate(-100%, -50%);
        }

        &:last-child {
          right: -20px;
          bottom: 5px;
          transform: translate(100%, 50%);

          div {
            transform: rotateZ(180deg);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .AboutUsItem {
    padding: 62px 14px 16px;

    &-avatar {
      width: 92px;
      height: 92px;
    }

    &-body {
      &__author {
        padding: 0 32px;
      }

      &__authorInfo {
        padding: 0 32px;
      }

      &__contentWrapper {
        padding: 18px 28px 0;
      }

      &__content {
        color: var(--cl-neutral-2);
        .quotes {
          font-size: 18px;

          &:first-child {
            left: -10px;
            top: 10px;
          }

          &:last-child {
            right: -10px;
            bottom: 10px;
          }
        }
      }
    }
  }
}
