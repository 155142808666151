.Scrollbar {
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #b5b6b700;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #c2c3c47f;
  }

  &.padding {
    padding-right: 10px;
    margin-right: -10px;
  }

  &.bold {
    scrollbar-width: unset;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b5b6b76f;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #c2c3c4;
    }
  }

  .simplebar-track {
    width: 5px;
    border-radius: 3px;

    &.simplebar-vertical {
      width: 6px;
    }

    .simplebar-scrollbar {
      &::before {
        left: 0;
        right: 0;
        background-color: #c2c3c4df;
      }
    }
  }
}
