.DatePicker,
#root-portal {
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming,
  .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    display: none;
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous {
    margin-top: 3px;
  }

  .react-datepicker__month-read-view {
    margin-right: 32px;
  }

  .react-datepicker__month-read-view,
  .react-datepicker__year-read-view {
    visibility: visible !important;
  }

  .react-datepicker-popper {
    left: 14px !important;
  }

  .react-datepicker__triangle {
    margin-left: -18px !important;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: #fff;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-top-color: transparent !important;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: rgba(0, 0, 0, 0.54);
  }

  .react-datepicker__navigation--previous {
    left: 10px;
  }

  .react-datepicker__navigation--next {
    right: 10px;
  }

  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    // filter: drop-shadow(0px 0px 6px rgba(0, 95, 113, 0.4));
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
      rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    background-color: white;
    border-radius: 4px !important;
    padding: 8px !important;
    border-color: white;
    width: 50% !important;
    top: 72px !important;
  }

  .react-datepicker__month-dropdown {
    left: 13% !important;
  }

  .react-datepicker__year-dropdown {
    left: 40% !important;
    max-height: 210px;
    height: 210px;
  }

  .react-datepicker__month-option,
  .react-datepicker__year-option {
    border-radius: 4px;
    padding: 2px 12px;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .react-datepicker__month-option:hover,
  .react-datepicker__year-option:hover {
    background-color: var(--cl-primary-10);
  }

  .react-datepicker {
    border: none;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.77);
    padding: 18px 12px;
    font-weight: 500;
    // filter: drop-shadow(0px 4px 8px rgba(0, 95, 113, 0.2));
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
      rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;

    &.small-year-1 {
      .react-datepicker__year-dropdown {
        height: 38px;
      }
    }

    &.small-year-2 {
      .react-datepicker__year-dropdown {
        height: 58px;
      }
    }

    &.small-year-3 {
      .react-datepicker__year-dropdown {
        height: 78px;
      }
    }

    &.small-year-4 {
      .react-datepicker__year-dropdown {
        height: 98px;
      }
    }

    &.show-time {
      .react-datepicker__navigation--next {
        right: 130px;
      }

      .react-datepicker__month-container {
        margin-right: 10px;
      }
    }

    .react-datepicker__time-list {
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #b5b6b77f;
      }
    }
  }

  .react-datepicker__time-list-item {
    &.react-datepicker__time-list-item--selected {
      background-color: var(--cl-primary) !important;
    }

    border-radius: 30px;
    margin: 6px 8px;
    height: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__navigation {
    top: 23px;
  }

  .react-datepicker__header {
    border: none;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    padding-top: 4px;
    background: none;
    margin-bottom: 8px;
  }

  .react-datepicker__header__dropdown {
    margin: 8px 0 6px;
  }

  .react-datepicker__header:before {
    content: '';
    position: absolute;
    left: 7%;
    bottom: 0;
    height: 1px;
    width: 86%;
    border-bottom: 2px solid none;
  }

  .react-datepicker__day-names {
    padding: 0 !important;
  }

  .react-datepicker__current-month {
    font-weight: 500;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: rgba(0, 0, 0, 0.77);
    margin-bottom: 0px;
  }

  .react-datepicker__day {
    border-radius: 60px;
  }

  .react-datepicker__day:hover {
    border-radius: 60px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__year-text--selected {
    background: var(--cl-primary);
  }

  .react-datepicker__day--selected:hover {
    background: var(--cl-primary);
  }

  .react-datepicker__day--outside-month {
    opacity: 0.65;
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown {
    border-radius: 4px;
    width: 40%;
    left: 30%;
    padding: 14px 0;
  }

  .react-datepicker__year-dropdown::-webkit-scrollbar {
    width: 0px;
  }

  .react-datepicker__navigation:hover *::before {
    border-color: var(--cl-primary);
  }

  .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: var(--cl-primary);
    border-right-color: var(--cl-primary);
  }

  .react-datepicker__year-option {
    padding: 0 12px;
  }

  .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
    display: none;
  }

  input {
    cursor: pointer;
  }

  .react-datepicker__month-text,
  .react-datepicker__year-text {
    padding: 3px 2px;
    border-radius: 20px;
    margin-bottom: 8px;
  }

  &.month,
  &.year {
    .react-datepicker__navigation {
      top: 17px;
    }

    .react-datepicker__header.react-datepicker-year-header {
      font-size: 17px;
      color: rgba(0, 0, 0, 0.77);
    }

    .react-datepicker {
      padding-bottom: 8px;
    }
  }

  &.year {
    .react-datepicker__year-wrapper {
      max-width: 70px;
    }

    .react-datepicker__header.react-datepicker-year-header {
      display: none;
    }
  }

  .react-datepicker__time-container {
    width: 110px;
  }

  .react-datepicker__header.react-datepicker__header--time {
    padding-bottom: 0px;
    margin-top: 7px;
    margin-left: 8px;
  }

  .react-datepicker-time__header {
    &::before {
      content: 'Thời gian';
      position: absolute;
      background-color: white;
      left: 11px;
      top: 5px;
      font-weight: 600;
    }
  }
}

#root-portal {
  .react-datepicker-popper {
    z-index: 1400;
  }
}

.react-datepicker-popper {
  z-index: 3;
}
