.Overview {
  padding: 56px 0 60px;
  position: relative;
  overflow: hidden;

  &.mt-0 {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .background {
    bottom: 0;
    position: absolute;
    background-image: url('../../../assets/images/home_overview_background.png');
    height: 236px;
    width: 100%;
    background-size: auto;
    background-position: center;
    z-index: -1;
  }

  &-row {
    display: flex;
    margin: 0 -8px;
    flex-wrap: wrap;

    .col {
      width: 20%;
      padding: 0 8px;
      height: 290px;

      & > div {
        background: var(--cl-primary);
        border-radius: 4px;
        height: 100%;
        position: relative;
        padding: 89px 40px 0;

        .icon {
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
          height: 80px;
          background: var(--cl-neutral-1-04);
          border-radius: 4px 4px 4px 24px;

          i {
            font-size: 44px;
            color: white;
          }
        }

        .count {
          color: var(--cl-semantic-1);
          margin-bottom: 7px;

          .plus {
            margin-left: 2px;
          }
        }

        .label {
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .Overview {
    margin: 12px 0 0px;
    padding: 24px 0 8px;

    .background {
      height: 100%;
    }

    &-row {
      justify-content: center;

      .col {
        width: 50%;
        height: 172px;
        margin-bottom: 16px;

        & > div {
          border-radius: 3px;
          padding: 16px;

          .icon {
            width: 66px;
            height: 66px;
            border-radius: 3px 3px 3px 18px;

            i {
              font-size: 36px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .Overview {
    &-row {
      .col {
        height: 109px;

        & > div {
          border-radius: 2px;
          padding: 16px;

          .count {
            margin-bottom: 4px;
          }

          .icon {
            width: 52px;
            height: 52px;
            border-radius: 2px 2px 2px 12px;

            i {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
}
