.ScientificPaperAttachedFile {
  .attachedFile-title {
    .attach-icon {
      font-size: 24px;
    }
    @media screen and (max-width: 860px) {
      .attach-icon {
        font-size: 20px;
      }
    }
  }
}
