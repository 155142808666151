.DepartmentChildItem {
  padding: 0 20px;

  & > div {
    height: 208px;
    background: var(--cl-neutral-1);
    border: 1px solid var(--cl-neutral-2);
    border-radius: 2px;
    padding: 27px 40px;
    color: white;

    i {
      font-size: 60px;
    }

    .line-below {
      width: 60px;
      margin: 8px 0 18px;
    }

    .regency {
      color: var(--cl-semantic-1);
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 860px) {
  .DepartmentChildItem {
    padding: 0 12px;

    & > div {
      width: 180px;
      height: 112px;
      padding: 14px;

      i {
        font-size: 40px;
      }

      .line-below {
        display: none;
      }

      .typo-24-6 {
        margin-top: 3px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      .regency {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
