.Title {
  padding-top: 80px;
  padding-bottom: 40px;

  &.paddingSmall {
    padding-top: 56px;
  }

  &.noPadding {
    padding-top: 0 !important;
  }

  &.paddingHalf {
    padding-top: 40px;
  }

  &-text {
    word-break: break-word;
    color: var(--cl-neutral-1);
    margin-bottom: 8px;
    text-transform: uppercase;
    &.primary {
      color: var(--cl-primary);
    }
  }

  @media screen and (max-width: 860px) {
    padding-top: 40px;
    padding-bottom: 24px;

    &.paddingSmall {
      padding-top: 16px;
    }

    &.paddingHalf {
      padding-top: 24px;
    }

    &-text {
      margin-bottom: 4px;
    }
  }
}
