.NewsItem {
  .banner {
    border-radius: 2px;
    width: 100%;
    height: 308px;
  }

  .newsTypeLabel {
    min-width: 83px;
    text-align: center;
  }

  .date-wrapper {
    margin-bottom: 16px;

    .date {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--cl-neutral-2);

      i {
        margin-right: 8px;
      }
    }
  }

  .title {
    color: var(--cl-neutral-1);
    transition: color 0.3s ease;
    margin-bottom: 8px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .title {
        color: var(--cl-semantic-1) !important;
      }
    }
  }

  .content {
    color: var(--cl-neutral-2);
  }

  .video {
    position: relative;
    margin-top: 0 !important;
    width: 50%;

    & .d-f {
      position: absolute;
      top: 70px;
      left: 0;

      & .newsTypeLabel {
        margin-bottom: 16px;
        padding: 4px 8px;
        font-weight: 500;
        // font-size: 10px;
        line-height: 16px;
        border-radius: 0px 2px 2px 0px;
        padding: 4px 13px 4px 40px;
      }
    }
  }

  &.blockType-1 {
    width: 100%;
    padding: 0 20px;

    .banner {
      width: 50%;
      height: 308px;
    }

    .body {
      width: calc(50% - 24px);
    }
  }

  &.blockType-2 {
    .banner {
      height: 408px;

      .newsTypeLabel {
        margin-bottom: 40px;
      }
    }

    .video {
      width: 100%;
      height: 408px;
    }
  }

  &.blockType-3 {
    .banner {
      .newsTypeLabel {
        margin-bottom: 20px;
      }
    }
  }

  &.blockType-4 {
    .banner {
      width: 240px;
      border-radius: 4px;
    }

    .video {
      // width: 240px;
      border-radius: 4px;
    }

    &.isPartner {
      .banner {
        height: 156px;
        border-radius: 2px;
      }

      .body {
        .content {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .body {
      width: calc(100% - 264px);
    }

    .title {
      margin-bottom: 8px;
    }
  }

  &.blockType-5 {
    .banner {
      height: 432px;
      border-radius: 4px;
    }

    .body {
      padding: 32px;

      .title {
        color: #ffffff;
        margin-bottom: 8px;
      }

      .content {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--cl-neutral-4);
      }
    }
  }

  &.blockType-6 {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 0px;

    .banner {
      height: 196px;
      border-radius: 2px;
    }

    .body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .title {
        margin-bottom: 16px;
        flex-grow: 1;
      }

      .content {
        height: 40px;
      }

      .date-wrapper {
        margin-bottom: 0;
      }

      .date {
        margin-top: 12px;
      }
    }
  }

  &.blockType-2,
  &.blockType-3 {
    .banner {
      display: flex;
      align-items: flex-end;

      .newsTypeLabel {
        padding: 4px 13px 4px 40px;
      }
    }

    .video {
      width: 100%;
    }

    .body {
      margin-top: 20px;
    }
  }

  &.blockType-1,
  &.blockType-4 {
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    .newsTypeLabel {
      padding: 4px 13px;
      border-radius: 2px;
      margin-right: 12px;
    }
  }

  &.blockType-4,
  &.blockType-6 {
    .banner {
      height: 196px;
    }

    .body {
      .content {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &.blockType-5,
  &.blockType-6 {
    .banner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .newsTypeLabel {
        padding: 4px 16px;
        margin: 12px;
        border-radius: 2px;
      }
    }

    .body {
      margin-top: 20px;
    }
  }

  &.research {
    .banner {
      width: 198px;
      height: 136px;
      border-radius: 2px;
    }

    .body {
      width: calc(100% - 222px);

      .title {
        margin-bottom: 12px;
      }

      .content {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .NewsItem {
    &.blockType-2 {
      .banner {
        height: 326px;
      }
    }

    &.blockType-3 {
      .banner {
        height: 250px;
      }
    }

    &.blockType-4 {
      margin-bottom: 24px;

      .banner {
        width: 152px;
        height: 152px;
        border-radius: 2px;
      }

      &.isPartner {
        .banner {
          height: 135px;
        }
      }

      .body {
        width: calc(100% - 174px);
      }

      &:last-child {
        margin-bottom: 0;
      }

      .date-wrapper {
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;

        .newsTypeLabel {
          font-size: 12px;
          line-height: 15px;
        }

        .date {
          margin-top: 0px;
          font-size: 12px;
          line-height: 15px;
        }

        i {
          font-size: 18px;
        }
      }
    }

    &.blockType-5 {
      .banner {
        height: 350px;
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .NewsItem {
    .date-wrapper {
      margin-bottom: 8px;

      .date {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;

        i {
          font-size: 20px;
        }
      }
    }

    .title {
      margin-bottom: 4px;
    }

    &.blockType-1,
    &.blockType-2 {
      .banner {
        height: 216px;
      }

      .body {
        margin-top: 12px;
      }
    }

    &.blockType-2 {
      .banner {
        .newsTypeLabel {
          margin-bottom: 16px;
          padding: 4px 8px;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          border-radius: 0px 2px 2px 0px;
        }
      }
    }

    &.blockType-4 {
      .banner {
        height: 103px;
      }

      &.isPartner {
        .banner {
          height: 114px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    &.blockType-5 {
      .banner {
        height: 227px;
        border-radius: 2px;

        .newsTypeLabel {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
        }
      }

      .body {
        padding: 16px;

        .title {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }

        .content {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &.research {
      .banner {
        width: 103px;
        height: 103px;
        border-radius: 2px;
      }

      .body {
        width: calc(100% - 119px);

        .title {
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }

        .content {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }

        .newsTypeLabel {
          min-width: 0;
          padding: 4px 8px;
        }

        .date-wrapper {
          margin-right: -8px;

          .date {
            i {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .NewsItem {
    &.blockType-4 {
      .banner {
        width: 103px;
        height: 103px;
        border-radius: 2px;
      }

      &.isPartner {
        .banner {
          width: 103px;
          height: 103px;
        }

        .body {
          .content {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .body {
        width: calc(100% - 119px);
      }

      .date-wrapper {
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;

        .newsTypeLabel {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
        }

        .date {
          margin-top: 0px;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          margin-right: -4px;
        }

        i {
          font-size: 16px;
        }
      }
    }
  }
}
