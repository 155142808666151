.Department {
  &General {
    background-color: var(--cl-primary);

    * {
      color: white;
    }

    .content {
      .left {
        width: calc(100% - 613px);

        .EditorViewer {
          padding-bottom: 0;

          * {
            color: white;
          }
        }
      }

      .img {
        width: 573px;
        height: 424px;
        position: relative;
        margin-top: 40px;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: -40px;
          right: -40px;
          border: 3px solid var(--cl-semantic-1);
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &Members {
    &-block {
      &:nth-child(2n + 1) {
        background-color: var(--cl-neutral-5);
      }
    }
  }

  &Subjects {
    .banner {
      background-image: url('../../../assets/images/home_overview_background.png');
      width: 100%;
      padding: 80px 0 158px;
    }

    &-slider {
      margin: 0 -20px;
    }

    &-arrow {
      &.slick-prev {
        left: -72px;
      }

      &.slick-next {
        right: -72px;
      }
    }
  }
  &Childs {
    .banner {
      background-image: url('../../../assets/images/home_overview_background.png');
      width: 100%;
      padding: 80px 0 158px;
    }

    &-slider {
      margin: 0 -20px;
    }

    &-arrow {
      &.slick-prev {
        left: -72px;
      }

      &.slick-next {
        right: -72px;
      }
    }
  }

  &Laboratories {
    &-content {
      padding: 48px 0;
      position: relative;
      color: white;

      .background {
        background-color: var(--cl-primary);
      }

      .left {
        position: relative;
        width: calc(52% - 32px);
        margin-right: 32px;

        &::before {
          content: '';
          background-image: url('../../../assets/images/departments/laboratory.jpg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;
          right: 0;
          width: 954px;
          height: calc(100% + 96px);
          top: -48px;
        }
      }

      .right {
        width: 48%;
      }

      .slick-slide {
        & > div {
          &:not(:first-child) {
            margin-top: 40px;
          }
        }
      }
    }

    &-arrow {
      bottom: 0;

      &.slick-prev {
        top: 100%;
        left: calc(100% + 40px);
      }

      &.slick-next {
        top: 100%;
        left: calc(100% + 104px);
      }
    }
  }

  &Cooperation {
    padding-bottom: 64px;
    overflow: hidden;

    .background {
      background-color: var(--cl-primary);
    }

    .container {
      flex-direction: row-reverse;
      align-items: stretch;
    }

    .cooperationImage {
      position: relative;
      width: 43%;

      &::before {
        content: '';
        position: absolute;
        width: 874px;
        top: -88px;
        bottom: -64px;
        background-image: url('../../../assets/images/departments/cooperation.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .content {
      width: calc(57%);
      padding: 40px 0px;
      box-sizing: border-box;

      & > div {
        padding: 40px;
        border: 2px solid var(--cl-semantic-1);
        border-right: none;

        .EditorViewer {
          * {
            color: white;
          }
        }
      }
    }
  }

  &Contact {
    .title {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1640px) {
  .Department {
    &Laboratories {
      &-arrow {
        &.slick-prev {
          top: calc(100% + 32px);
          left: calc(100% - 104px);
        }

        &.slick-next {
          top: calc(100% + 32px);
          left: calc(100% - 40px);
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .DepartmentSubjects {
    &-arrow {
      &.slick-prev {
        left: -48px;
      }

      &.slick-next {
        right: -48px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .Department {
    &General {
      .content {
        .left {
          width: calc(100% - 468px);
        }

        .img {
          width: 420px;
          height: 300px;
          margin: auto 24px;

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: -24px;
            right: -24px;
            border: 1px solid var(--cl-semantic-1);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .Department {
    &General {
      .content {
        .left {
          width: 100%;
        }

        .img {
          margin: 24px 0 22px;
          width: calc(100% - 24px);
          height: 206px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: -24px;
            right: -24px;
            border: 1px solid var(--cl-semantic-1);
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &Subjects {
      .banner {
        height: 160px;
        padding: 24px 0;
      }

      .slider-card {
        width: 43vw;
      }

      &-slider {
        width: 100vw;
      }
    }
    &Childs {
      .banner {
        height: 160px;
        padding: 24px 0;
      }

      .slider-card {
        width: 43vw;
      }

      &-slider {
        width: 100vw;
      }
    }

    &Laboratories {
      &-container {
        flex-direction: column;
      }

      &-content {
        padding: 40px 0 0;
        position: relative;
        color: white;

        .left {
          width: 100%;
          margin-right: 0;
          height: 188px;

          &::before {
            width: 100%;
            height: 228px;
            top: -40px;
            border-radius: 2px;
          }
        }

        .right {
          width: 100%;
          padding-bottom: 45px;
        }
      }
    }

    &Cooperation {
      padding-bottom: 0px;

      .container {
        flex-direction: column;
        padding: 24px;
      }

      .cooperationImage {
        width: 100%;
        height: 228px;

        &::before {
          width: 100%;
          top: 0;
          bottom: auto;
          height: 228px;
        }
      }

      .content {
        width: 100%;
        padding: 0;

        & > div {
          padding: 16px;
          border: 2px solid var(--cl-semantic-1);
          border-top: none;
        }
      }
    }

    &Contact {
      .title {
        margin-bottom: 8px;
      }
    }
  }
}
