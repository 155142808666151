.Image {
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--cl-primary);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.loading {
      display: none;
    }
  }

  i {
    font-size: 26px !important;
  }
}
