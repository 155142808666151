.ScreenDetail {
  &-info {
    .newsTypeLabel {
      padding: 4px 13px;
      margin-right: 40px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &-link {
    margin-top: -20px;
    margin-bottom: 40px;
    display: flex;
    align-items: self-start;
    & i {
      font-size: 24px;
      margin-right: 12px;
    }
  }
}

@media screen and (max-width: 860px) {
  .ScreenDetail {
    &-info {
      .newsTypeLabel {
        padding: 4px 16px;
        margin-right: 12px;
        font-size: 10px;
        line-height: 16px;
        margin-bottom: 24px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .ScreenDetail {
    &-info {
      .newsTypeLabel {
        padding: 4px 8px;
      }
    }
  }
}
