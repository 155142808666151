@import './mixins';

:root {
  --cl-primary: #242056;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 5000000s ease-in-out 0s;
  }
}

textarea {
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #b5b6b700;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #c2c3c47f;
  }
}

.background-image {
  background-position: center;
  background-repeat: no-repeat;
  // background-size: contain;
  background-size: cover;

  &.cover {
    background-size: cover;
  }
}

.primary {
  color: var(--cl-primary);
}

.cursor-pointer {
  cursor: pointer;
}

.modal-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--cl-primary);
}

.version {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 99999;
  padding: 5px 15px;
  border-radius: 100px;
  background-color: white;
  font-weight: 500;
  box-shadow: 0 1px 8px rgba(153, 153, 153, 0.5);
  font-size: 15px;
}

button {
  white-space: nowrap;
}

.MuiCheckbox-root.Mui-checked.Mui-disabled {
  color: var(--cl-primary);
  opacity: 0.5;
}

.MuiDialogActions-root.MuiDialogActions-spacing {
  padding: 16px 0 0 8px;

  &.pt-0 {
    padding-top: 0;
  }
}

.FormContainer {
  max-height: calc(95vh - 200px);

  & > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.a,
a {
  text-decoration: none;
  transition: color 0.3s ease;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.b {
  font-weight: 600;
}

.i {
  font-style: italic;
}

.icon-arrow-ios-down {
  transition: transform 0.3s ease;
}

.rotate-animation {
  transition: transform 0.3s ease;
}

.rotate-180 {
  transform: rotateZ(180deg);
}

.max-line-1 {
  @include maxLine(1);
}

.max-line {
  @include maxLine(2);
}

.max-line-3 {
  @include maxLine(3);
}

.max-line-4 {
  @include maxLine(4);
}

.max-line-7 {
  @include maxLine(7);
}

.block-error {
  height: 200px;
}

@media screen and (max-width: 860px) {
  .MuiCircularProgress-root {
    max-width: 40px;
    max-height: 40px;
  }
}
