.UploadImage {
  width: 180px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-btn.MuiButton-root {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.1),
      0px 1px 5px 0px rgba(0, 0, 0, 0.08);

    .upload-image-wrapper {
      width: 100%;
      height: 100%;
      background-color: white;
    }

    &.has-image {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      padding: 0;
      position: relative;
      color: #cde;
      background-color: white;

      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #0004;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
      }

      .remove {
        position: absolute;
        top: 8px;
        right: 6px;
        color: #ddd;
        padding: 0;
        min-width: 0;
        min-height: 0;
        width: 24px;
        height: 24px;
        opacity: 0.7;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        i {
          font-size: 20px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }

    i {
      font-size: 30px;
    }

    &.Mui-disabled {
      opacity: 1;
    }
  }
}

.UploadMultiImages {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  &-item {
    width: 25%;
    padding: 10px;

    @media screen and (max-width: 1440px) {
      width: 33.33%;
    }
    @media screen and (max-width: 760px) {
      width: 50%;
    }
    @media screen and (max-width: 460px) {
      width: 100%;
    }

    .UploadImage {
      width: 100%;
    }
  }
}
