.ResearchItem {
  padding: 24px;

  .left {
    margin: 4.5px 20px 4.5px 0;

    .circle {
      width: 12px;
      height: 12px;
      background: var(--cl-semantic-1);
      border-radius: 50%;
      margin-bottom: 3px;
    }

    .dash {
      border-left: 1px dashed var(--cl-neutral-3);
      flex-grow: 1;
    }
  }

  .right {
    width: calc(100% - 32px);

    .date {
      margin-top: 4px;
    }
  }
}

@media screen and (max-width: 860px) {
  .ResearchItem {
    padding: 16px 8px 16px 16px;
    background: var(--cl-neutral-5);

    .left {
      margin: 4.5px 12px 4.5px 0;

      .circle {
        width: 8px;
        height: 8px;
      }
    }

    .right {
      width: calc(100% - 20px);

      .title {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
