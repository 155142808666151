.AdminEditor {
  min-height: calc(56vh + 42px);

  .ck-media__wrapper {
    & > div {
      z-index: 1;
    }
  }

  .ck.ck-labeled-field-view__status {
    display: none;
  }
  .ck.ck-content .image {
    margin-top: 0.9em;
  }
  .ck.ck-content .image-inline {
    margin-top: 0.9em;
  }
  .ck.ck-content .image-style-side {
    max-width: 100%;
  }

  .ck.ck-content ul,
  .ck.ck-content ul li {
    list-style-type: inherit !important;
  }

  .ck.ck-content ul,
  .ck.ck-content ol {
    padding-left: 12px !important;
  }

  .ck.ck-content.ck-editor__editable {
    min-height: 56vh;
    max-height: 62vh;
    padding: 4px 18px;
    box-shadow: none !important;

    * {
      // font-family: 'Mulish' !important;
      line-height: 1.6 !important;
      // color: inherit;
    }

    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #b5b6b700;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: #c2c3c47f;
    }
  }

  &.small {
    .ck.ck-content.ck-editor__editable {
      min-height: 250px;
      max-height: 250px;
    }
  }

  &.error {
    .ck.ck-content.ck-editor__editable {
      border-color: var(--cl-error);
    }
  }
}

.EditorViewer {
  * {
    // font-size: 16px;
    // line-height: 22px;
    // letter-spacing: 0.5px;
    // color: #333333;
    // margin-bottom: 16px;
    // font-family: 'Mulish', sans-serif;
    word-wrap: break-word;
  }

  ol,
  ul {
    padding-left: 20px;
  }

  // table,
  // td,
  // th {
  //   border: 1px solid #bbb;
  // }

  td,
  th {
    padding: 6px;
  }

  table {
    border-collapse: collapse;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  img {
    max-width: 100%;
  }
  &.ck-content {
    img {
      margin-top: 0.9em !important;
    }
    .image-inline {
      display: inline-flex;
      max-width: 100%;
      align-items: flex-start;
      margin-top: 0.9em;
      margin-bottom: 0.9em;
    }
    .image-style-side {
      max-width: 100%;
    }
    .image {
      margin-top: 0;
    }
  }

  figure {
    padding: 0;
    margin: 0;

    &.image {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      &.image_resized {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:not(.image) {
      margin-right: auto;
      margin-left: 0;
      text-align: left;
      &.image_resized {
        margin-right: auto;
        margin-left: 0;
      }
    }

    &.image-style-side {
      margin-left: auto;
      margin-right: 0;
      text-align: right;
      &.image_resized {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  a {
    color: #0000dd;
  }

  // h2 {
  //   font-size: 23px;
  //   line-height: 1.3;
  // }

  // h3 {
  //   font-size: 20px;
  //   line-height: 1.3;
  // }

  // h4 {
  //   font-size: 17px;
  // }

  div[data-oembed-url] {
    & > div {
      z-index: 1;
    }
  }
}

.ck-content blockquote {
  background-color: #f5f5f5 !important;
  border-left: 4px solid #64b5f6 !important;
  padding: 16px 1.5em !important;
  margin: 12px 0 !important;

  * {
    margin: 0 !important;
  }
}

.App {
  .ck-content {
    table {
      // width: auto;

      th,
      td {
        // width: 140px;
      }
    }
  }
}

.ck-editor__editable:not(.ck-read-only) .ck-media__wrapper > :not(.ck-media__placeholder) {
  pointer-events: initial !important;
}

// .ck-editor__editable:not(.ck-read-only)
//   .ck-widget_selected
//   .ck-media__wrapper
//   > :not(.ck-media__placeholder) {
//   pointer-events: initial !important;
// }

.ck-body-wrapper {
  .ck.ck-form__row.ck-table-form__border-row {
    display: none !important;
  }
}
