.Header {
  &-top {
    background: #f9f9f9;
    height: 72px;

    a {
      margin: 0 16px;
    }

    .name {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--cl-neutral-1);
    }

    .links {
      font-size: 14px;
      line-height: 20px;
    }
    .animate-charcter {
      margin-left: 35px;
      font-size: 18px;
      font-weight: 700;
      background-image: linear-gradient(
        -225deg,
        #231557 0%,
        #44107a 29%,
        #ff1361 67%,
        #fff800 100%
      );
      background-size: auto auto;
      background-clip: border-box;
      background-size: 200% auto;
      color: #fff;
      background-clip: text;
      // text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: textclip 2s linear infinite;
      display: inline-block;
      cursor: pointer;
      &:hover {
        animation: none;
        scale: 1.1;
        transition: 0.2s linear;
      }
    }

    @keyframes textclip {
      to {
        background-position: 200% center;
      }
    }
  }

  &-search {
    & > div {
      width: 224px;
      margin-right: 16px;
      margin-left: 34px;
    }
  }

  &-bottom {
    background: #ffffff;
    height: 109px;

    .logo {
      img {
        margin-right: 12px;
      }

      display: flex;
      font-family: 'Roboto Condensed';
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
      text-transform: uppercase;
      color: var(--cl-primary);
    }

    .menu {
      display: flex;

      & > * {
        margin-left: 24px;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }

  &-avatar.MuiButton-root {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    min-width: 0;
    position: relative;

    .Header-avatar__border {
      border: 1px solid transparent;
      border-radius: inherit;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      transition: border-color 0.3s ease;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .Header-avatar__border {
          border-color: var(--cl-semantic-1);
        }
      }
    }

    &.open {
      .Header-avatar__border {
        border-color: var(--cl-semantic-1);
      }
    }
  }

  &-menu {
    .MuiPaper-root {
      margin-top: 0;
      padding: 16px;
      border-radius: 2px;

      .MuiMenuItem-root {
        padding: 8px;
        border-radius: 2px;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &:hover {
          background: var(--cl-accent-1);
        }
      }
    }
  }
  &-changePage.MuiButton-root {
    padding: 8px 12px;
    font-size: 12px;
  }
}

.MobileHeader {
  display: none;
  padding: 24px;
  justify-content: space-between;
  align-items: center;

  .logo {
    display: flex;
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    text-transform: uppercase;
    color: var(--cl-primary);

    img {
      margin-right: 12px;
    }
  }

  .right {
    i {
      font-size: 32px;
      color: var(--cl-neutral-1);
      margin-left: 18px;
      cursor: pointer;
    }
  }
}

.Footer {
  margin-top: 40px;
  background-color: var(--cl-neutral-1);
  padding: 72px 0 40px;

  .logo {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: white;
    margin-bottom: 40px;

    img {
      margin-right: 12px;
    }
  }

  .col-3 {
    padding-right: 42px;

    &:last-child {
      &:not(:first-child) {
        padding-left: 48px;
        padding-right: 6px;
      }
    }
  }

  .name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 16px;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    color: var(--cl-neutral-2);
    opacity: 0.8;
    margin-bottom: 3px;
  }

  .content {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    opacity: 0.8;
    margin-bottom: 16px;
  }

  .col-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .col-content {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-3);
    margin-bottom: 16px;
  }

  .hr {
    height: 1px;
    background: #ffffff;
    opacity: 0.1;
  }

  &-end {
    margin-top: 27px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;

    .copyright {
      opacity: 0.5;
    }

    .icons {
      a {
        text-decoration: none;
        color: white;
        font-size: 20px;
        margin-left: 28px;
      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;

      .icons {
        margin-top: 16px;

        a {
          margin-left: 0;
          margin-right: 28px;
        }
      }
    }
  }

  &-goToTop.MuiButton-root {
    position: fixed !important;
    right: 40px;
    bottom: 40px;
    border-radius: 100px !important;
    background-color: white;
    min-width: 56px;
    width: 56px;
    height: 56px;
    font-size: 28px;
    color: var(--cl-neutral-2);
    z-index: 2;
    box-shadow: 0 1px 8px rgba(153, 153, 153, 0.5);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--cl-primary);
        background-color: var(--cl-accent-1);
      }
    }

    @media screen and(max-width: 1440px) {
      width: 48px;
      height: 48px;
      font-size: 24px;
      min-width: 48px;

      right: 24px;
      bottom: 24px;
    }

    @media screen and(max-width: 860px) {
      min-width: 32px;
      width: 32px;
      height: 32px;
      font-size: 16px;
    }
  }
  &-analiytics{
    cursor: pointer;
    position: fixed;
    right: 40px;
    bottom: 110px;
    padding: 0 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: linear-gradient(180deg, var(--cl-secondary-1) 0%, var(--cl-secondary-1) 100%);
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-radius: 30px;
    box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    z-index: 99;
    width: 56px;
    height: 56px;
    font-size: 12px;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    &:hover {
      width: 166px;

      & > span {
        margin-left: 8px;
        width: 110px;
        color: white;
      }
    }

    & > span {
      margin-left: 0px;
      width: 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
    }
    @media screen and(max-width: 1440px) {
      width: 48px;
      height: 48px;
      min-width: 48px;

      right: 24px;
      bottom: 80px;
    }

    @media screen and(max-width: 860px) {
      min-width: 32px;
      width: 32px;
      height: 32px;
      font-size: 10px;
      
      bottom: 70px;
      img{
        width: 16px;
      }
      &:hover {
        width: 140px;}
    }
  }
}

.Search-paper {
  padding: 16px;
}

.HistorySearch-item {
  padding: 8px 4px 8px 8px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--cl-neutral-1);
  justify-content: space-between;
  cursor: pointer;
  border-radius: 2px;
  overflow: hidden;
  transition: background-color 0.3s ease;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--cl-accent-1);
    }
  }

  i {
    font-size: 20px;

    &:first-child {
      margin-right: 6px;
    }

    &:last-child {
      margin-left: 6px;
    }

    &.icon-close {
      opacity: 0.8;
      font-size: 15px;

      &:hover {
        opacity: 1;
      }
    }
  }

  & > div {
    overflow: hidden;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.mobile {
    padding: 6px 12px 6px 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    i {
      font-size: 24px;

      &:first-child {
        margin-right: 12px;
      }

      &:last-child {
        font-size: 20px;
        margin-left: 12px;
      }
    }
  }
}

.UserSidebar {
  padding: 24px;

  &-header {
    margin-bottom: 28px;

    i {
      font-size: 24px;
    }
  }

  .animate-charcter {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    // text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: block;
    padding: 12px 0;
    cursor: pointer;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }

  &-label a {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    padding: 12px 0;
    display: block;
  }

  &-hr {
    border-top: 1px solid var(--cl-neutral-4);
    margin: 28px 0;
  }
}

@media screen and (max-width: 1280px) {
  .Header {
    &-top {
      a {
        margin: 0 10px;
      }

      .name {
        font-size: 14px;
      }

      .links {
        font-size: 13px;
      }
    }

    &-search {
      & > div {
        width: 230px;
        margin-right: 16px;
        margin-left: 30px;
      }
    }

    &-signIn.MuiButton-root {
      padding: 10px 14px;
    }
    &-changePage.MuiButton-root {
      padding: 8px 12px;
      font-size: 12px;
    }

    &-bottom {
      height: 109px;

      .logo {
        font-size: 19px;
        text-align: center;
        max-width: 230px;

        img {
          margin-right: -8px;
        }
      }

      .menu {
        & > * {
          margin-left: 19px;
          font-size: 14px;
        }
      }
    }
  }

  .Footer {
    margin-top: 36px;
    padding: 48px 0 32px;

    .col-3 {
      padding-right: 32px;

      &:last-child {
        &:not(:first-child) {
          padding-left: 32px;
        }
      }
    }

    .name {
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 12px;
    }

    .col-title {
      font-size: 14px;
      line-height: 21px;
    }

    .col-content {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 12px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .Header {
    display: none;
  }

  .MobileHeader {
    display: flex;
  }
}

@media screen and (max-width: 860px) {
  .Header {
    &-signIn.MuiButton-root {
      padding: 4px 12px;
    }
    &-changePage.MuiButton-root {
      padding: 4px 8px !important;
      height: auto;
    }
  }

  .Footer {
    margin-top: 26px;
    padding: 32px 0 40px;

    .logo {
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 24px;

      img {
        width: 43px;
        object-fit: cover;
      }
    }

    .name {
      max-width: 284px;
    }
  }
}

@media screen and(max-width: 560px) {
  .MobileHeader {
    .logo {
      font-size: 16px;
      img {
        width: 40px;
        margin-right: 8px;
      }
    }

    .right {
      i {
        font-size: 24px;
        margin-left: 12px;
      }
    }
  }
}

.ModalSearch {
  &-header {
    top: 52px;
    position: absolute;
    left: 16px;
    right: 16px;
  }

  &-body {
    margin-top: 116px;
  }
}
