.select_main_research {
  .checked_zone {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .checked_item {
      margin-top: 12px;
      margin-right: 12px;
    }
  }
}
