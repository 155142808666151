.SearchItem {
  display: flex;

  .banner {
    width: 240px;
    height: 156px;
    border-radius: 2px;
    margin-right: 24px;
  }

  .body {
    width: calc(100% - 264px);

    .label-icon-wrapper {
      margin-bottom: 20px;
    }

    .title {
      color: var(--cl-neutral-1);
      margin-bottom: 8px;
    }

    .content {
      color: var(--cl-neutral-2);
    }
  }

  &:hover {
    .title {
      color: var(--cl-semantic-1) !important;
    }
  }
}

@media screen and (max-width: 860px) {
  .SearchItem {
    .banner {
      width: 170px;
      height: 128px;
      margin-right: 20px;
    }

    .body {
      width: calc(100% - 190px);

      .label-icon-wrapper {
        margin-bottom: 18px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .SearchItem {
    .banner {
      width: 103px;
      height: 103px;
      margin-right: 16px;
    }

    .body {
      width: calc(100% - 119px);

      .label-icon-wrapper {
        margin-bottom: 16px;
      }

      .title {
        margin-bottom: 7px;
      }
    }
  }
}
