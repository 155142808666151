.Breadcrumb {
  &-area {
    margin: 40px 0;
    background: #fff;
    position: relative;
    display: flex;
    align-items: center;
  }

  &-arrow {
    display: flex;
    align-items: center;
    margin: 0 4px;
  }

  &-list {
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--cl-neutral-2);

    &.hasBanner {
      width: 100%;
      justify-content: center;
    }

    .icon-arrow-ios-right {
      color: var(--cl-neutral-1);
      font-size: 20px;
    }

    & > li {
      display: flex;
      align-items: center;
      position: relative;
      white-space: nowrap;

      & > a {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        cursor: pointer;
        color: var(--cl-neutral-2);

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            text-decoration: underline;
          }
        }
      }

      &:last-child {
        overflow: hidden;
        text-overflow: ellipsis;

        & > a {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: var(--cl-primary);
          text-decoration: none !important;
          cursor: default;
          pointer-events: none;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &-banner {
    padding: 146px 0 111px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 80px;
    background-color: rgb(80, 75, 119);

    &.noMargin {
      margin-bottom: 0 !important;
    }

    .Breadcrumb-list {
      margin-bottom: 24px;

      .Breadcrumb-arrow {
        & > span {
          color: white;
        }
      }

      & > li {
        & > a {
          color: var(--cl-neutral-4);
        }

        & > span {
          color: white;
        }

        &:last-child {
          & > a {
            color: white;
          }
        }
      }
    }

    .line-below {
      width: 100px;
      height: 10px;
    }
  }

  &-title {
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0 -12px 12px -12px;
  }
}

@media screen and (max-width: 860px) {
  .Breadcrumb {
    &-area {
      margin: 0 0 40px;

      .Breadcrumb {
        &-list {
          display: -webkit-inline-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-word;
          white-space: pre-line;
          -webkit-line-clamp: 2;

          & > li {
            display: inline;
          }
        }

        &-arrow {
          width: 16px;
          height: 16px;
          display: inline-block;
          position: relative;

          span {
            position: absolute;
            top: 72%;
            left: 0;
            transform: translateY(-48%);
          }
        }
      }
    }

    &-banner {
      padding: 40px 16px;
      margin-bottom: 40px;

      .line-below {
        width: 40px;
        height: 4px;
        border-radius: 1px;
      }
    }

    &-title {
      margin: 0 14px 8px 14px;
    }

    &-list {
      padding: 0;
      flex-wrap: wrap;

      .icon-arrow-ios-right {
        font-size: 16px;
      }

      & > li {
        white-space: pre-wrap;

        & > a {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }

        &:last-child {
          // width: 100%;

          & > a {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .Breadcrumb {
    &-banner {
      padding: 24px 16px;

      .MuiContainer-root {
        padding-left: 0px;
        padding-right: 0px;
      }

      .Breadcrumb-list {
        margin-bottom: 16px;
      }
    }

    &-list {
      & > li {
        & > a {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
        }

        &:last-child {
          & > a {
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
