.Input {
  background: #ffffff;
  position: relative;

  .MuiOutlinedInput-input {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--cl-neutral-1);
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 2px;

    legend {
      font-size: 10.5px;
    }
  }

  .MuiInputAdornment-root {
    font-size: 20px;
    color: var(--cl-neutral-3);
    transition: color 0.3s ease;
  }

  .MuiInputAdornment-positionStart {
    margin-left: 16px;
    margin-right: -4px;
  }

  .MuiInputAdornment-positionEnd {
    margin-right: 16px;
    margin-left: -4px;
    font-size: 24px;
    cursor: pointer;
    opacity: 0.8;

    .icon-close {
      color: var(--cl-primary);
    }

    &:hover {
      opacity: 1;
    }
  }

  .MuiInputBase-root {
    height: 52px;
    padding-left: 0;
    padding-right: 0;

    .MuiOutlinedInput-input {
      padding: 15px 16px 17px;
    }

    &.MuiInputBase-sizeSmall {
      height: 40px;

      .MuiInputAdornment-positionStart {
        margin-left: 12px;
      }

      .MuiInputAdornment-positionEnd {
        margin-right: 12px;
      }

      .MuiOutlinedInput-input {
        padding: 9px 12px 11px;
      }
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--cl-neutral-2);
      }
    }

    &.Mui-focused {
      .MuiInputAdornment-root {
        color: var(--cl-neutral-2);
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid var(--cl-secondary-1);
      }
    }

    .icon-password {
      font-size: 20px;

      &.hasValue {
        color: var(--cl-neutral-1);
      }
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--cl-semantic-3);
      }

      .icon-password {
        color: var(--cl-semantic-3);
      }
    }
  }

  .MuiInputLabel-root.MuiInputLabel-formControl {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &:not(.Mui-focused) {
      &:not(.Mui-error) {
        color: var(--cl-neutral-3);
      }
    }
  }

  .requiredPlaceholder {
    position: absolute;
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: var(--cl-neutral-3);
    cursor: text;

    span {
      color: var(--cl-error);
    }
  }
}

.InputSearch {
  .MuiInputBase-adornedStart {
    padding-left: 10px !important;
  }

  .icon-search {
    font-size: 20px;
  }
}

.MuiFormHelperText-root.Mui-error {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  margin-left: 0px;
  margin-top: 4px;
}

@media screen and (max-width: 860px) {
  .mt-20 {
    margin-top: 20px;
  }

  .Input {
    .MuiInputAdornment-positionEnd {
      font-size: 16px;
    }

    .MuiInputLabel-root {
      // transform: translate(14px, 10px) scale(1);
    }

    .MuiInputBase-root {
      height: 40px;

      .MuiOutlinedInput-input {
        padding: 10px 16px;
      }
    }

    .requiredPlaceholder {
      padding: 10px 16px;
    }
  }
}
