.RenderField {
  &-field {
    margin-bottom: 12px;
  }

  &-label {
    margin-top: 10px;
    margin-right: 12px;

    &.small {
      font-size: 14px;
    }
  }
}
