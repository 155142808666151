.IntroductionItem {
  height: 300px;
  border-radius: 2px;
  color: white;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;

  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
  }

  &-content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    padding: 36px;

    i {
      font-size: 64px;
      margin-bottom: 16px;
      // transition: all 0.3s ease;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .IntroductionItem-overlay {
        backdrop-filter: blur(4px);
        background: rgba(2, 89, 81, 0.7);
      }

      // i {
      //   font-size: 0px;
      // }
    }
  }
}

@media screen and (max-width: 860px) {
  .IntroductionItem {
    height: 245px;

    &-content {
      padding: 24px;
      font-size: 20px !important;
      line-height: 32px !important;

      i {
        font-size: 60px;
      }
    }
  }
}
