.LaboratoryItem {
  padding: 0 8px;

  .name {
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 860px) {
  .LaboratoryItem {
    .name {
      margin-bottom: 8px;
    }

    .description {
      margin-top: 16px;
    }
  }
}
