.CommentCard {
  .avatar {
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }
  &-input {
    padding-bottom: 20px;

    .input-wrapper {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      .btn {
        text-align: end;
        margin-top: 20px;
        .MuiButton-root {
          &.save {
            background-color: var(--cl-primary);
            margin-left: 10px;
            box-shadow: none;
            @media (hover: hover) and (pointer: fine) {
              &:hover:not([disabled]) {
                box-shadow: none;
                background: var(--cl-accent-1);
                color: var(--cl-primary);
              }
            }
            &:disabled {
              opacity: 0.6;
              color: #fff;
            }
          }
          &.cancel {
            color: var(--cl-primary);
          }
        }
      }
      .MuiInputBase-root {
        font-size: 14px;
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: var(--cl-neutral-2);
          }
        }

        &.Mui-focused {
          .MuiInputAdornment-root {
            color: var(--cl-neutral-2);
          }

          .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--cl-secondary-1);
          }
        }
      }
    }
  }
  &-field {
    padding-bottom: 34px;
    .comment-content {
      .userName {
        font-size: 14px;
        color: var(--cl-neutral-1);
        font-weight: 500;
      }
      .createDate {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #727779;
      }
      .content {
        padding-top: 3px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #727779;
        width: 100%;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
  }
}
