.Slider {
  .slick {
    &-dots {
      bottom: -24px;

      & li {
        margin: 0 2px;
        padding: 0;
        height: 0px;
        width: 12px;

        &.slick-active {
          & button:before {
            background: var(--cl-primary);
          }
        }

        & button {
          padding: 0;
          width: 100%;
          height: 2px;
          position: relative;

          &:before {
            content: '';
            width: 100%;
            height: 100%;
            background: var(--cl-neutral-4);
            opacity: 1;
            border-radius: 2px;
          }
        }
      }
    }
  }

  &.dark {
    .slick {
      &-dots {
        & li {
          &.slick-active {
            & button:before {
              background: white;
            }
          }

          & button {
            &:before {
              background: var(--cl-neutral-2);
            }
          }
        }
      }
    }
  }
}
