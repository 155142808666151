.StudentInfoItem {
  display: flex;

  .banner {
    border-radius: 2px;
    width: 198px;
    height: 136px;
    margin-right: 24px;
  }

  .body {
    width: calc(100% - 222px);
  }

  .title {
    color: var(--cl-neutral-1);
    transition: color 0.3s ease;
    margin-bottom: 12px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .title {
        color: var(--cl-semantic-1) !important;
      }
    }
  }

  .content {
    color: var(--cl-neutral-2);
  }

  .label-icon-wrapper {
    margin-bottom: 12px;
    padding: 4px 0;
  }
}

@media screen and (max-width: 860px) {
  .StudentInfoItem {
    .banner {
      width: 103px;
      height: 103px;
      margin-right: 16px;
    }

    .body {
      width: calc(100% - 119px);
    }

    .title {
      margin-bottom: 7px;
      font-size: 14px;
      line-height: 20px;
    }

    .label-icon-wrapper {
      margin-bottom: 16px;
      padding: 0;
    }
  }

  .StudentInfoAllForms-description.description {
    line-height: 20px;
    font-weight: 500;
  }
}
