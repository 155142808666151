.Select {
  .MuiInputBase-root {
    background: var(--cl-neutral-5);
    border-radius: 2px;

    input {
      padding: 12px 0 12px 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--cl-neutral-1);
      height: 24px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }

    &.Mui-focused {
      background: #fff7b9;

      input {
        color: var(--cl-primary);
      }
    }
  }
  &-item {
    &.noWhireSpace {
      overflow: unset !important;
      text-overflow: unset !important;
      white-space: pre-wrap !important;
    }
  }

  &-item.MuiMenuItem-root {
    padding: 12px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--cl-neutral-1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;

    &.border {
      padding: 10px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    &:hover {
      background-color: var(--cl-accent-1) !important;
    }
  }

  .icon-arrow-ios-down {
    font-size: 24px;
    &.rotate-180 {
      color: var(--cl-primary);
    }
  }

  &-readOnly {
    .MuiOutlinedInput-root {
      cursor: pointer !important;

      input {
        cursor: pointer !important;
      }
    }
  }

  &-border {
    .MuiInputBase-root {
      background: white;

      input {
        padding: 12px 0 12px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--cl-neutral-1);
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: var(--cl-neutral-3);
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: var(--cl-neutral-2);
        }
      }

      &.Mui-focused {
        background: white;

        .MuiOutlinedInput-notchedOutline {
          border-color: var(--cl-neutral-2);
          border-width: 1px;
        }

        input {
          color: var(--cl-neutral-1);
        }
      }

      &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
          border-color: var(--cl-error);
        }

        .icon-arrow-ios-down {
          color: var(--cl-error);
        }
      }
    }

    .icon-arrow-ios-down {
      font-size: 20px;
      color: var(--cl-neutral-1);
    }
  }
}

.SelectPaper-search {
  padding: 12px;
}

.SelectPaper-paper {
  max-height: 288px;

  .simplebar-track {
    .simplebar-scrollbar {
      &::before {
        background-color: #929394af;
      }
    }

    &.simplebar-vertical {
      width: 10px;
    }
  }
}

.MuiPaper-root.MuiPaper-rounded {
  border-radius: 2px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
}

@media screen and (max-width: 860px) {
  .Select {
    .MuiInputBase-root {
      border-radius: 2px;

      input {
        padding: 8px 0 8px 12px;
        font-size: 12px;
        line-height: 20px;
        height: 20px;
      }
    }

    &-item.MuiMenuItem-root {
      padding: 8px 12px;
      font-size: 12px;
      line-height: 20px;
      height: 36px;
      min-height: 36px;
    }

    .icon-arrow-ios-down {
      font-size: 20px;
    }

    &-border {
      .MuiInputBase-root {
        input {
          padding: 10px 0 10px 16px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          height: 20px;
        }
      }
    }
  }

  .SelectPaper-paper {
    max-height: 216px;
  }
}
